import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import 'firebase/storage';
import { Observable, BehaviorSubject } from 'rxjs';
import { ConstantVariables } from 'src/const/constant';
import { map } from 'rxjs/operators';
import { ConsoleService } from 'src/app/services/console.service';
import { AngularFireFunctions } from '@angular/fire/functions';


@Injectable({
  providedIn: 'root'
})
export class TodosService {

  public todosTask = new BehaviorSubject<string | null>(null);
  public todosTaskData = this.todosTask.asObservable();

  constructor(
    private constVar: ConstantVariables,
    private afs: AngularFirestore,
    private console: ConsoleService,
    private angularFireFunctions: AngularFireFunctions,

  ) { }

  public createId() { return this.afs.createId(); }

  public addTodosData(uId: string, docId: string, todosObj: any): Observable<any> {
    if (docId) {
      if (todosObj.reorderType) { delete todosObj.reorderType; }
      if (todosObj.editStatus) { delete todosObj.editStatus; }
      if (todosObj.changeIcon) { delete todosObj.changeIcon; }
      if (todosObj.remainingDays) { delete todosObj.remainingDays; }
      if (todosObj.dateStatus) { delete todosObj.dateStatus; }
      if (todosObj.newTodo) { delete todosObj.newTodo; }
      if (todosObj.showEditableMode) { delete todosObj.showEditableMode; }
      const obs = new Observable((observer) => {
        this.afs.collection('users').doc(uId)
          .collection('todos').doc(docId).set(todosObj).then(async () => {
            observer.next({
              message: this.constVar.todosAddSuccess,
              status: 200,
            });
          }).catch((error) => {
            observer.next({
              message: error.message ? error.message : this.constVar.commonError,
              status: error.code ? error.code : 400,
            });
          });
      });
      return obs;
    }
  }

  public updateTodosData(uId: string, docId: string, todosObj: any): Observable<any> {
    if (todosObj.reorderType) { delete todosObj.reorderType; }
    if (todosObj.editStatus) { delete todosObj.editStatus; }
    if (todosObj.changeIcon) { delete todosObj.changeIcon; }
    if (todosObj.newTodo) { delete todosObj.newTodo; }
    if (todosObj.dateStatus) { delete todosObj.dateStatus; }
    if (todosObj.remainingDays) { delete todosObj.remainingDays; }
    if (todosObj.showEditableMode) { delete todosObj.showEditableMode; }
    if (docId) {
      const obs = new Observable((observer) => {
        this.afs.collection('users').doc(uId)
          .collection('todos').doc(docId)
          .update(todosObj).then(() => {
            observer.next({
              message: this.constVar.todosUpdateSuccess,
              status: 200,
            });
          }).catch((error) => {
            observer.next({
              message: error.message ? error.message : this.constVar.commonError,
              status: error.code ? error.code : 400,
            });
          });
      });
      return obs;
    }
  }

  public deleteTodos(docId: string, currentUserId: string): Observable<any> {
    const obs = new Observable((observer) => {
      this.afs.collection('users').doc(currentUserId).collection('todos')
        .doc(docId).delete().then(async () => {
          observer.next({
            message: this.constVar.todosDeleted,
            status: 200,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : this.constVar.commonError,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public getCompletedTodosListByDate(currentUserId: string, dateKey: any, goalId?): Observable<any> {
    const dateView = new Date(dateKey);
    dateView.setHours(23, 59, 59, 999);
    // tslint:disable-next-line: no-bitwise
    const calenderDate = dateView.getTime() / 1000 | 0;

    const date = new Date();
    date.setHours(23, 59, 59, 999);
    // tslint:disable-next-line: no-bitwise
    const todayDate = date.getTime() / 1000 | 0;

    const yesterDate = new Date();
    yesterDate.setHours(23, 59, 59, 999);
    yesterDate.setDate(yesterDate.getDate() - 1);
    // tslint:disable-next-line: no-bitwise
    const yesterDayDate = yesterDate.getTime() / 1000 | 0;

    const calenyesterDate = new Date(dateKey);
    calenyesterDate.setHours(23, 59, 59, 999);
    calenyesterDate.setDate(calenyesterDate.getDate() - 1);
    // tslint:disable-next-line: no-bitwise
    const calenderyesterDayDate = calenyesterDate.getTime() / 1000 | 0;

    const obj = new Observable((observer) => {
      let allData = [];
      if (currentUserId && calenderDate <= todayDate && calenderDate > yesterDayDate) {
        // GET ONLY TODAY COMPLETED TODOS
        this.afs.collection('users').doc(currentUserId)
          .collection('todos', (ref: any) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            // next page
            query = query.where('elapsedTime', '<=', todayDate);
            query = query.where('elapsedTime', '>', yesterDayDate);
            if (goalId) {
              query = query.where('goals.goalId', '==', goalId);
            }
            query = query.where('done', '==', true);
            query = query.orderBy('elapsedTime', 'desc');
            return query;
          }).snapshotChanges().pipe().forEach(actions => {
            allData = [];
            actions.forEach(doc => {
              const docId = doc.payload.doc.id;
              const data: any = doc.payload.doc.data();
              allData.push({ docId, ...data });
            });
            observer.next({
              status: 200,
              message: this.constVar.getDailyDoneTodosSuccess,
              data: allData
            });
          }).catch((error) => {
            this.console.log('error', error);
            observer.next({
              message: error.message ? error.message : this.constVar.commonError,
              status: error.code ? error.code : 400,
            });
          });
      } else if (currentUserId && calenderDate <= todayDate && calenderDate > calenderyesterDayDate) {
        // GET ONLY BEFORETODAY COMPLETED TODOS BY SEPRETED DATE
        this.afs.collection('users').doc(currentUserId)
          .collection('todos', ref => ref
            .where('elapsedTime', '<=', calenderDate)
            .where('elapsedTime', '>', calenderyesterDayDate)
            .where('done', '==', true)
            .orderBy('elapsedTime', 'desc')
          ).snapshotChanges().pipe().forEach(actions => {
            allData = [];
            actions.forEach(doc => {
              const docId = doc.payload.doc.id;
              const data: any = doc.payload.doc.data();
              allData.push({ docId, ...data });
            });
            observer.next({
              status: 200,
              message: this.constVar.getAllCompletedTodosSuccess,
              data: allData
            });

          }).catch((error) => {
            this.console.log('error', error);
            observer.next({
              message: error.message ? error.message : this.constVar.commonError,
              status: error.code ? error.code : 400,
            });
          });
      }
    });
    return obj;
  }

  public getCompletedTodosForGoal(userId: any, goalId: string): Observable<any> {
    return this.afs.collection('users').doc(userId)
      .collection('todos', ref => ref.where('done', '==', true)
        .where('goals.goalId', '==', goalId)
        .orderBy('elapsedTime', 'desc'))
      .snapshotChanges().pipe(
        map((actions) => {
          return actions.map(doc => {
            const data = doc.payload.doc.data();
            const id = doc.payload.doc.id;
            return { docId: id, ...data };
          });
        })
      );
  }

  public getUserTodosList(currentUserId: any, limit: number): Observable<any> {
    const obj = new Observable((observer) => {
      let allData = [];
      this.afs.collection('users').doc(currentUserId)
        .collection('todos', ref =>
          ref.where('done', '==', false)
            .orderBy('sortBy', 'desc')
            .limit(limit))
        .snapshotChanges().pipe().forEach(actions => {
          allData = [];
          actions.forEach(doc => {
            const docId = doc.payload.doc.id;
            const data: any = doc.payload.doc.data();
            allData.push({ docId, ...data });
          });
          observer.next({
            status: 200,
            message: this.constVar.getUndoneTodosMsg,
            data: allData
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : this.constVar.commonError,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obj;
  }

  public getUserTodosListByDate(currentUserId: any, dateKey: Date): Observable<any> {
    const dateView = new Date(dateKey);
    dateView.setHours(23, 59, 59, 999);
    // tslint:disable-next-line: no-bitwise
    const calenderDate = dateView.getTime() / 1000 | 0;

    const date = new Date();
    date.setHours(23, 59, 59, 999);
    // tslint:disable-next-line: no-bitwise
    const todayDate = date.getTime() / 1000 | 0;

    const yesterDate = new Date();
    yesterDate.setHours(23, 59, 59, 999);
    yesterDate.setDate(yesterDate.getDate() - 1);
    // tslint:disable-next-line: no-bitwise
    const yesterDayDate = yesterDate.getTime() / 1000 | 0;

    const calenyesterDate = new Date(dateKey);
    calenyesterDate.setHours(23, 59, 59, 999);
    calenyesterDate.setDate(calenyesterDate.getDate() - 1);
    // tslint:disable-next-line: no-bitwise
    const calenderyesterDayDate = calenyesterDate.getTime() / 1000 | 0;

    const obj = new Observable((observer) => {
      let allData = [];
      if (currentUserId && calenderDate <= todayDate && calenderDate > yesterDayDate) {
        // GET ONLY TODAY COMPLETED TODOS
        this.afs.collection('users').doc(currentUserId)
          .collection('todos', ref => ref
            .where('elapsedTime', '<=', todayDate)
            .where('elapsedTime', '>', yesterDayDate)
            .where('done', '==', true)
          ).snapshotChanges().pipe().forEach(actions => {
            allData = [];
            actions.forEach(doc => {
              const docId = doc.payload.doc.id;
              const data: any = doc.payload.doc.data();
              allData.push({ docId, ...data });
            });
            observer.next({
              status: 200,
              message: this.constVar.getAllDoneTodosSuccess,
              data: allData
            });
          }).catch((error) => {
            observer.next({
              message: error.message ? error.message : this.constVar.commonError,
              status: error.code ? error.code : 400,
            });
          });
      } else if (currentUserId && calenderDate <= todayDate && calenderDate > calenderyesterDayDate) {
        // GET ONLY BEFORETODAY COMPLETED TODOS BY SEPRETED DATE
        this.afs.collection('users').doc(currentUserId)
          .collection('todos', ref => ref
            .where('elapsedTime', '<=', calenderDate)
            .where('elapsedTime', '>', calenderyesterDayDate)
            .where('done', '==', true)
          ).snapshotChanges().pipe().forEach(actions => {
            allData = [];
            actions.forEach(doc => {
              const docId = doc.payload.doc.id;
              const data: any = doc.payload.doc.data();
              allData.push({ docId, ...data });
            });
            observer.next({
              status: 200,
              message: this.constVar.getAllCompletedTodosSuccess,
              data: allData
            });

          }).catch((error) => {
            observer.next({
              message: error.message ? error.message : this.constVar.commonError,
              status: error.code ? error.code : 400,
            });
          });
      }
    });
    return obj;
  }

  public updateTaskName(task: string | null) {
    this.todosTask.next(task);
  }
  public getLastTodoData(currentUserId: any): Observable<any> {
    const obj = new Observable((observer) => {
      let allData = [];
      this.afs.collection('users').doc(currentUserId)
        .collection('todos', ref =>
          ref.orderBy('sortBy', 'desc').limit(1)
        )
        .snapshotChanges().pipe().forEach(actions => {
          allData = [];
          actions.forEach(doc => {
            const docId = doc.payload.doc.id;
            const data: any = doc.payload.doc.data();
            allData.push({ docId, ...data });
          });
          observer.next({
            status: 200,
            message: this.constVar.getTodosListMsg,
            data: allData
          });
        }).catch((error) => {
          this.console.log(error);
          observer.next({
            message: error.message ? error.message : this.constVar.commonError,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obj;
  }

  public async addTodosWithBatch(uId: string, todosObj: any) {
    // add todos with batch
    const batch = this.afs.firestore.batch();
    todosObj.map((res: any, index: number) => {
      if (res.editStatus) { delete res.editStatus; }
      // const dateStr = new Date();
      // const dateId = dateStr.getTime();
      // const todosDocId = uId.substring(0, 5) + dateId + index;
      const nycRef = this.afs.collection('users').doc(uId).collection('todos')
        .doc(res.docId).ref;
      batch.set(nycRef, res);
    });
    return await batch.commit();
  }

  public getTodos(userId: any, queries: any, sortByObj?: any, limit?: any): Observable<any> {
    return this.afs.collection('users').doc(userId)
      .collection('todos', (ref: any) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        if (queries && queries.length > 0) {
          queries.forEach((queryItem: any) => {
            query = query.where(queryItem.field, queryItem.condition, queryItem.value);
          });
        }
        if (sortByObj && sortByObj.length > 0) {
          sortByObj.forEach((item: any) => {
            query = query.orderBy(item.field, item.value);
          });
        }
        if (limit) {
          query = query.limit(limit);
        }
        return query;
      }).snapshotChanges().pipe(
        map((actions: any) => {
          return actions.map((doc: any) => {
            const data = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data };
          });
        })
      );
  }


  public addNextDateTodo(data: any, userId: any) {
    data.userId = userId;
    const newObs = new Observable((observer) => {
      const callable = this.angularFireFunctions.httpsCallable('addNextTodo');
      const obs = callable({ allData: data });
      obs.subscribe(resp => {
        observer.next({
          status: 200,
          res: resp,
        });
      }, err => {
        observer.next({
          status: 500,
          message: 'error',
        });
      });
    });
    return newObs;

  }

}
