import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TodosService } from 'src/app/api-service/todos/todos.service';
import { DateService } from '../date-service/date.service';
import * as introJs from 'intro.js/intro.js';
import { ConstantVariables } from 'src/const/constant';
import { LocalNotificationRequest, Plugins } from '@capacitor/core';
import { ConsoleService } from '../console.service';
const { LocalNotifications } = Plugins;
import * as party from 'party-js';
import { DatePipe } from '@angular/common';
declare var party: any;

@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  introJS = introJs();

  public userProfileDataSource = new BehaviorSubject<any>(null);
  public UserProfileData = this.userProfileDataSource.asObservable();

  public userLoginStatusDataSource = new BehaviorSubject<any>(null);
  public userLoginStatusData = this.userLoginStatusDataSource.asObservable();

  public loginStatusDataSource = new BehaviorSubject<any>(null);
  public loginStatusData = this.loginStatusDataSource.asObservable();

  public profileIconDataSource = new BehaviorSubject<any>(null);
  public profileIcon = this.profileIconDataSource.asObservable();

  public selectedDateDataSource = new BehaviorSubject<any>(null);
  public getChangeDate = this.selectedDateDataSource.asObservable();

  public refreshDashboardDataSource = new BehaviorSubject<any>(null);
  public getRefreshDashboard = this.refreshDashboardDataSource.asObservable();

  public isChartViewSectionSource = new BehaviorSubject<any>(false);
  public isChartViewSection = this.isChartViewSectionSource.asObservable();

  public changeProUserSource = new BehaviorSubject<any>(null);
  public changeProUser = this.changeProUserSource.asObservable();

  public openHelpOpoverSource = new BehaviorSubject<any>(null);
  public openHelpOpover = this.openHelpOpoverSource.asObservable();


  public inAppSource = new BehaviorSubject<any>(null);
  public inAppSourceData = this.inAppSource.asObservable();


  constructor(
    private dateService: DateService,
    private todosService: TodosService,
    public constVar: ConstantVariables,
    public console: ConsoleService,
    private datePipe: DatePipe,
  ) { }

  public profileUpdate(data: any) {
    this.userProfileDataSource.next(data);
  }

  public showHelpPopover(type: any) {
    this.openHelpOpoverSource.next(type);
  }

  public inApp(value: any) {
    this.inAppSource.next(value);
  }

  public goToLearn(type: string, todoId: any, goalsId: any, habitTrackersId: any, journalsId: any): void {
    const stepsData = JSON.parse(JSON.stringify(this.constVar.stepsData));
    if (type === 'todos' || type === 'todos-goal') {
      stepsData.steps.push({
        title: this.constVar.helpTodo.learnTodoWelcome.title,
        intro: this.constVar.helpTodo.learnTodoWelcome.text,
      });
      if (document.querySelector(`#${todoId.todosId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${todoId.todosId}`),
          title: this.constVar.helpTodo.learnTodoMsg.title,
          intro: this.constVar.helpTodo.learnTodoMsg.text,
        });
      }
      if (document.querySelector(`#${todoId.addTodoId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${todoId.addTodoId}`),
          title: this.constVar.helpTodo.learnAddTodo.title,
          intro: this.constVar.helpTodo.learnAddTodo.text,
        });
      }
      if (document.querySelector(`#${todoId.todoItemId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${todoId.todoItemId}`),
          title: this.constVar.helpTodo.learnTodoItem.title,
          intro: this.constVar.helpTodo.learnTodoItem.text,
        });
      }
      if (document.querySelector(`#${todoId.todoTimerId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${todoId.todoTimerId}`),
          title: this.constVar.helpTodo.learnTodoTimer.title,
          intro: this.constVar.helpTodo.learnTodoTimer.text,
          tooltipClass: 'custom_intro_left'
        });
      }
      if (document.querySelector(`#${todoId.todoListId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${todoId.todoListId}`),
          title: this.constVar.helpTodo.learnTodoList.title,
          intro: this.constVar.helpTodo.learnTodoList.text,
          tooltipClass: 'custom_intro_left'
        });
      }
      if (document.querySelector(`#${todoId.todoDateId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${todoId.todoDateId}`),
          title: this.constVar.helpTodo.learnTodoDate.title,
          intro: this.constVar.helpTodo.learnTodoDate.text,
          tooltipClass: 'custom_intro_center'
        });
      }
    }

    if (type === 'goals' || type === 'todos-goal') {
      stepsData.steps.push({
        title: this.constVar.helpGoal.learnGoalWelcome.title,
        intro: this.constVar.helpGoal.learnGoalWelcome.text,
      });
      if (document.querySelector(`#${goalsId.goalsLearnId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${goalsId.goalsLearnId}`),
          title: this.constVar.helpGoal.learnGoalMsg.title,
          intro: this.constVar.helpGoal.learnGoalMsg.text,
        });
      }
      if (document.querySelector(`#${goalsId.addGoalId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${goalsId.addGoalId}`),
          title: this.constVar.helpGoal.learnAddGoal.title,
          intro: this.constVar.helpGoal.learnAddGoal.text,
          tooltipClass: 'custom_intro_left'
        });
      }
      if (document.querySelector(`#${goalsId.goalItemId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${goalsId.goalItemId}`),
          title: this.constVar.helpGoal.learnGoalItem.title,
          intro: this.constVar.helpGoal.learnGoalItem.text,
        });
      }
      if (document.querySelector(`#${goalsId.allGoalsId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${goalsId.allGoalsId}`),
          title: this.constVar.helpGoal.learnGoalList.title,
          intro: this.constVar.helpGoal.learnGoalList.text,
          tooltipClass: 'custom_intro_left'
        });
      }
      if (document.querySelector(`#${goalsId.goalsDateId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${goalsId.goalsDateId}`),
          title: this.constVar.helpGoal.learnGoalDate.title,
          intro: this.constVar.helpGoal.learnGoalDate.text,
          tooltipClass: 'custom_intro_center'
        });
      }
    }

    if (type === 'habitTrackers') {
      stepsData.steps.push({
        title: this.constVar.helpTracker.learnHabitTrackerWelcome.title,
        intro: this.constVar.helpTracker.learnHabitTrackerWelcome.text,
      });
      if (document.querySelector(`#${habitTrackersId.habitTrackersId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${habitTrackersId.habitTrackersId}`),
          title: this.constVar.helpTracker.learnHabitTrackerMsg.title,
          intro: this.constVar.helpTracker.learnHabitTrackerMsg.text,
        });
      }
      if (document.querySelector(`#${habitTrackersId.addTrackersId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${habitTrackersId.addTrackersId}`),
          title: this.constVar.helpTracker.learnHabitTrackerAdd.title,
          intro: this.constVar.helpTracker.learnHabitTrackerAdd.text,
          tooltipClass: 'custom_intro_left'
        });
      }
      if (document.querySelector(`#${habitTrackersId.itemTrackersId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${habitTrackersId.itemTrackersId}`),
          title: this.constVar.helpTracker.learnHabitTrackerItem.title,
          intro: this.constVar.helpTracker.learnHabitTrackerItem.text,
        });
      }
      if (document.querySelector(`#${habitTrackersId.habitTrackersDateId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${habitTrackersId.habitTrackersDateId}`),
          title: this.constVar.helpTracker.learnHabitTrackerDate.title,
          intro: this.constVar.helpTracker.learnHabitTrackerDate.text,
          tooltipClass: 'custom_intro_center'
        });
      }
    }

    if (type === 'journals') {
      stepsData.steps.push({
        title: this.constVar.helpJournal.learnJournalWelcome.title,
        intro: this.constVar.helpJournal.learnJournalWelcome.text,
      });
      if (document.querySelector(`#${journalsId.memoriesSelectedId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${journalsId.memoriesSelectedId}`),
          title: this.constVar.helpJournal.learnMemorySelcted.title,
          intro: this.constVar.helpJournal.learnMemorySelcted.text,
          tooltipClass: 'custom_intro_right'
        });
      }
      if (document.querySelector(`#${journalsId.gratitudeSelectedId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${journalsId.gratitudeSelectedId}`),
          title: this.constVar.helpJournal.learnGratitudeSelcted.title,
          intro: this.constVar.helpJournal.learnGratitudeSelcted.text,
          tooltipClass: 'custom_intro_left'
        });
      }
      if (document.querySelector(`#${journalsId.memoriesAddId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${journalsId.memoriesAddId}`),
          title: this.constVar.helpJournal.learnMemoryAdd.title,
          intro: this.constVar.helpJournal.learnMemoryAdd.text,
          tooltipClass: 'custom_intro_right'
        });
      }
      if (document.querySelector(`#${journalsId.gratitudeAddId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${journalsId.gratitudeAddId}`),
          title: this.constVar.helpJournal.learnGratitudeAdd.title,
          intro: this.constVar.helpJournal.learnGratitudeAdd.text,
          tooltipClass: 'custom_intro_left'
        });
      }
      if (document.querySelector(`#${journalsId.memoriesItemId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${journalsId.memoriesItemId}`),
          title: this.constVar.helpJournal.learnMemoryItem.title,
          intro: this.constVar.helpJournal.learnMemoryItem.text,
        });
      }
      if (document.querySelector(`#${journalsId.memoriesItemIdHome}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${journalsId.memoriesItemIdHome}`),
          title: this.constVar.helpJournal.learnMemoryItemDashboard.title,
          intro: this.constVar.helpJournal.learnMemoryItemDashboard.text,
        });
      }
      if (document.querySelector(`#${journalsId.memoriesActionButtonId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${journalsId.memoriesActionButtonId}`),
          title: this.constVar.helpJournal.learnMemoryActionButton.title,
          intro: this.constVar.helpJournal.learnMemoryActionButton.text,
          tooltipClass: 'custom_intro_left'
        });
      }
      if (document.querySelector(`#${journalsId.gratitudeItemId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${journalsId.gratitudeItemId}`),
          title: this.constVar.helpJournal.learnGratituteItem.title,
          intro: this.constVar.helpJournal.learnGratituteItem.text,
        });
      }
      if (document.querySelector(`#${journalsId.gratitudeActionButtonId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${journalsId.gratitudeActionButtonId}`),
          title: this.constVar.helpJournal.learnGratitudeActionButton.title,
          intro: this.constVar.helpJournal.learnGratitudeActionButton.text,
          tooltipClass: 'custom_intro_left'
        });
      }
      if (document.querySelector(`#${journalsId.journalDateId}`)) {
        stepsData.steps.push({
          element: document.querySelector(`#${journalsId.journalDateId}`),
          title: this.constVar.helpJournal.learnJournalDate.title,
          intro: this.constVar.helpJournal.learnJournalDate.text,
          tooltipClass: 'custom_intro_center'
        });
      }
    }

    if (type === 'bookmarks') {
      stepsData.steps.push({
        title: this.constVar.helpBookmark.learnBookmarkWelcome.title,
        intro: this.constVar.helpBookmark.learnBookmarkWelcome.text,
      });
      if (document.querySelector('#bookmarks')) {
        stepsData.steps.push({
          element: document.querySelector('#bookmarks'),
          title: this.constVar.helpBookmark.learnBookmarkMsg.title,
          intro: this.constVar.helpBookmark.learnBookmarkMsg.text,
        });
      }

      if (document.querySelector('#addBookmark')) {
        stepsData.steps.push({
          element: document.querySelector('#addBookmark'),
          title: this.constVar.helpBookmark.learnAddBookmarkMsg.title,
          intro: this.constVar.helpBookmark.learnAddBookmarkMsg.text,
          tooltipClass: 'custom_intro_center'
        });
      }

      if (document.querySelector('#bookmarkItem')) {
        stepsData.steps.push({
          element: document.querySelector('#bookmarkItem'),
          title: this.constVar.helpBookmark.learnBookmarkItem.title,
          intro: this.constVar.helpBookmark.learnBookmarkItem.text,
        });
      }
    }
    introJs().setOptions(stepsData).start();
  }

  public userLoginStatus(data: any) {
    this.userLoginStatusDataSource.next(data);
  }

  public upgradeUser(data: any) {
    this.changeProUserSource.next(data);
  }

  public userStatus(data: any) {
    this.loginStatusDataSource.next(data);
  }

  public clickProfileIcon(data: any) {
    this.profileIconDataSource.next(data);
  }

  public changeDate(date: any) {
    const convertedDate = this.dateService.setTimeFormat(date);
    this.selectedDateDataSource.next(convertedDate);
  }

  public refreshDashboard(date: any) {
    this.refreshDashboardDataSource.next(date);
  }

  public getPageLocation(status: boolean) {
    this.isChartViewSectionSource.next(status);
  }

  public combineDueDateAndNoDueDateData(todayDueDateData: any, noDueDateData: any): Observable<any> {
    const obj = new Observable((observer) => {
      const combineData = [];
      const seprateTodayData = [];
      const seprateChildFutureData = [];
      if (todayDueDateData) { combineData.push(...todayDueDateData); }
      if (noDueDateData) { combineData.push(...noDueDateData); }
      if (combineData) {
        combineData.map((item: any, index: number) => {
          item.remainingDays = item.dueDate ?
            this.dateService.convertDateIntoDays(item.dueDate) : '';
          item.dateStatus = item.dueDate ?
            this.dateService.checkDateIslessThanToday(item.dueDate) : '';
          if (item.previousTodo && !item.previousTodo.done && !item.dueDate) {
            seprateChildFutureData.push(item);
          } else { seprateTodayData.push(item); }
        });
      }
      const allData = {
        combineData: seprateTodayData,
        seprateChildData: seprateChildFutureData
      };
      observer.next({
        status: 200,
        data: allData
      });
    });
    return obj;
  }

  public combineNoDueDateDataAndChildFutureTask(noDueDateDataWithParent: any, futureTaskData: any): Observable<any> {
    const obj = new Observable((observer) => {
      const tempCombineData = [];
      if (futureTaskData) {
        tempCombineData.push(...futureTaskData);
      }
      if (noDueDateDataWithParent) {
        tempCombineData.push(...noDueDateDataWithParent);
      }
      const allData = {
        combineData: tempCombineData,
      };
      observer.next({
        status: 200,
        data: allData
      });
    });
    return obj;
  }

  public getTodosDueToday(userId: any, currentDate: any, goalId?: string, limit?: any, title?: any): Observable<any> {
    const newCurrentDate = this.dateService.dateForIonDate(currentDate);
    const obj = new Observable((observer) => {
      const date1 = new Date(newCurrentDate);
      const newDateFormat1 = date1.toISOString();
      const tempDate = this.dateService.dateForIonDate(new Date());
      const date2 = new Date(tempDate);
      date2.setDate(date2.getDate() + 1);
      const newDateFormat2 = new Date(new Date(new Date().setHours(23, 59, 59, 999))
        .toString().split('GMT')[0] + ' UTC').toISOString();
      const queries = [
        {
          field: 'dueDate',
          condition: '>',
          value: newDateFormat1
        },
        {
          field: 'dueDate',
          condition: '<',
          value: newDateFormat2
        },
        {
          field: 'done',
          condition: '==',
          value: false
        },
      ];
      if (goalId) {
        queries.push({
          field: 'goals.goalId',
          condition: '==',
          value: goalId
        });
      }
      if (title) {
        queries.push({
          field: 'previousTodo',
          condition: '==',
          value: ''
        });
      }
      // this.todosService.getTodos('3PgRS8IZDdbIfbMG8aYCrM97Dd73', queries, null, limit)
      this.todosService.getTodos(userId, queries, null, limit)
        .subscribe((responseData: any) => {
          observer.next({
            status: 200,
            data: responseData
          });
        });
    });
    return obj;
  }

  public getTodosNoDueDate(userId: any, goalId?: string, limit?: any, title?: any): Observable<any> {
    const obj = new Observable((observer) => {
      const queries = [
        {
          field: 'dueDate',
          condition: '==',
          value: '',
        },
        {
          field: 'done',
          condition: '==',
          value: false
        },
      ];
      if (goalId) {
        queries.push({
          field: 'goals.goalId',
          condition: '==',
          value: goalId
        });
      }
      if (title) {
        queries.push({
          field: 'previousTodo',
          condition: '==',
          value: ''
        });
      }
      const sortByObj = [
        {
          field: 'sortBy',
          value: 'desc'
        },
      ];
      this.todosService.getTodos(userId, queries, sortByObj, limit)
        .subscribe((responseData: any) => {
          observer.next({
            status: 200,
            data: responseData
          });
        });
    });
    return obj;
  }

  public getTodosDueAfterToday(userId: any, goalId?: string): Observable<any> {
    const obj = new Observable((observer) => {
      const newDateFormat = new Date(new Date(new Date().setHours(23, 59, 59, 999))
        .toString().split('GMT')[0] + ' UTC').toISOString();
      const queries = [
        {
          field: 'dueDate',
          condition: '>',
          value: newDateFormat,
        },
        {
          field: 'done',
          condition: '==',
          value: false
        }
      ];
      if (goalId) {
        queries.push({
          field: 'goals.goalId',
          condition: '==',
          value: goalId
        });
      }
      // const sortByObj = [
      //   {
      //     field: 'dueDate',
      //     value: 'asc'
      //   },
      // ];
      this.todosService.getTodos(userId, queries)
        .subscribe((responseData: any) => {
          observer.next({
            status: 200,
            data: responseData
          });
        });
    });
    return obj;
  }

  public getTodosDueBeforeToday(currentDate: any, userId: any, goalId?: string): Observable<any> {
    const obj = new Observable((observer) => {
      const dateView = new Date(currentDate);
      const newDate = this.dateService.dateForIonDate(new Date(dateView));
      const newDate1 = new Date(newDate);
      const newDateFormat = newDate1.toISOString();
      const queries = [
        {
          field: 'dueDate',
          condition: '<',
          value: newDateFormat,
        },
        {
          field: 'dueDate',
          condition: '!=',
          value: '',
        },
        {
          field: 'done',
          condition: '==',
          value: false
        },
      ];
      if (goalId) {
        queries.push({
          field: 'goals.goalId',
          condition: '==',
          value: goalId
        });
      }
      const sortByObj = [
        {
          field: 'dueDate',
          value: 'desc'
        },
      ];
      this.todosService.getTodos(userId, queries, sortByObj)
        .subscribe((responseData: any) => {
          observer.next({
            status: 200,
            data: responseData
          });
        });
    });
    return obj;
  }

  private createNotification(itemObj: any, type: string): void {
    if (itemObj.dueDate
      && itemObj.notifiCationId
      && new Date(itemObj.dueDate) > new Date()
      && !itemObj.done
    ) {
      const timeFormat = this.dateService.convertForNotificaton(itemObj.dueDate);
      const label = type === 'todos' ? 'Todo' : 'Goal';
      const notifs = LocalNotifications.schedule({
        notifications: [{
          title: `BetterToday ${label} at ${timeFormat}`,
          body: `${itemObj.task}`,
          id: Number(itemObj.notifiCationId),
          schedule: { at: new Date(itemObj.dueDate) },
          attachments: null,
          extra: null,
          autoCancel: true,
        }]
      });
    }
  }

  private async manageNotification(itemObj: any, type: string) {
    LocalNotifications.getPending().then((res: any) => {
      if (res.notifications && res.notifications.length) {
        const oldNotification = res.notifications.find((item: any) => item.id === itemObj.notifiCationId);
        if (oldNotification) {
          // this.console.log('if oldNotification, cancel old create new', oldNotification);
          const notifications: LocalNotificationRequest[] = [oldNotification];
          LocalNotifications.cancel({ notifications });
          this.createNotification(itemObj, type);
        } else {
          // this.console.log('if no oldNotification create new');
          this.createNotification(itemObj, type);
        }
      } else {
        // this.console.log('if no panding notification create new');
        this.createNotification(itemObj, type);
      }
    }, (error: any) => {
      this.console.log('error', error);
    });
  }

  public manageNotificationData(dataArray: any, type: string): void {
    // CREATE NEW NOTIFICATION BY NOTIFICATION ID
    dataArray.map((resp: any) => {
      if (resp.notifiCationId) {
        this.manageNotification(resp, type);
      }
    });
  }

  public playAudio(soundName: any): void {
    const audio = new Audio();
    audio.src = soundName;
    audio.load();
    audio.play();
  }

  public createAnimation(event: any): void {
    party.confetti(event, {
      size: party.variation.range(.7, 1.5),
      count: party.variation.range(10, 20),
    });
  }

  filteTrackerShowData(res, date) {
    const currentDate = new Date(date).getTime();
    const stuffData = [];
    const stuffDataFutcher = [];
    const stuffDataCompleted = [];

    this.console.log('res%%%%%%%%%%%%%%%%%%%', res);
    res.map((item: any) => {
      if (item.schedule) {
        const endDate = new Date(item.schedule.endDate.seconds * 1000).getTime();
        const startDate = new Date(item.schedule.startDate.seconds * 1000).getTime();

        let tempNextDueDate: any;
        if (item.schedule.nextDueDate.seconds) {
          tempNextDueDate = new Date(item.schedule.nextDueDate.seconds * 1000).getTime();
        }

        let tempStartDate: any;
        if (item.schedule.startDate.seconds) {
          tempStartDate = new Date(item.schedule.startDate.seconds * 1000).getTime();
        }

        let tempEndDate: any;
        if (item.schedule.endDate.seconds) {
          tempEndDate = new Date(item.schedule.endDate.seconds * 1000).getTime();
        }
        // const currentDate = new Date().getTime();
        let showDueOnDate;
        if (tempNextDueDate !== '' && tempNextDueDate > currentDate) {
          showDueOnDate = `Due on: ${this.datePipe.transform(new Date(tempNextDueDate), 'EEE dd MMM')}`;
        } else if (tempStartDate !== '' && tempStartDate > currentDate) {
          showDueOnDate = `Due on: ${this.datePipe.transform(new Date(tempStartDate), 'EEE dd MMM')}`;
        } else if (tempEndDate !== '' && tempEndDate > currentDate) {
          showDueOnDate = `Due on: ${this.datePipe.transform(new Date(tempEndDate), 'EEE dd MMM')}`;
        } else {
          if (tempEndDate) {
            showDueOnDate = `Ended on: ${this.datePipe.transform(new Date(tempEndDate), 'EEE dd MMM')}`;
          } else if (tempNextDueDate) {
            showDueOnDate = `Due on: ${this.datePipe.transform(new Date(tempNextDueDate), 'EEE dd MMM')}`;
          } else {
            showDueOnDate = '';
          }
        }

        if (item.schedule.repetitionType === 'daily') {
          if (item.schedule.endDate.seconds || item.schedule.startDate.seconds) {
            // tslint:disable-next-line:max-line-length
            if ((currentDate >= startDate && !endDate) || (!startDate && currentDate <= endDate) || (currentDate >= startDate && currentDate <= endDate)) {
              stuffData.push(item);
            } else {

              // if ((currentDate < startDate && startDate) || (currentDate > endDate && endDate)) {
                // item.addVal = true;
              // }

              if (item.input.value && item.input.value !== 0 && item.input.value !== '0' && item.input.value !== '') {
                stuffData.push(item);
              } else {
                item.showDueOnDate = showDueOnDate;
                if(currentDate > endDate && endDate){
                   stuffDataCompleted.push(item);                           
                }else{                  
                  stuffDataFutcher.push(item);
                }
              }
            }
          } else {
            stuffData.push(item);
          }
        } else {
          const conNextDueDate = this.dateService.settTimeEmpty(item.schedule.nextDueDate);
          const conLastDueDate = this.dateService.settTimeEmpty(item.schedule.lastDueDate);
          const conCurrentDate = new Date(currentDate).setHours(0, 0, 0);
          // tslint:disable-next-line:max-line-length
          if (item.schedule.updateStatus && conCurrentDate >= conNextDueDate && conLastDueDate !== conNextDueDate) {
            item.schedule.lastDueDate = item.schedule.nextDueDate,
              item.schedule.updateStatus = false;
            // this.updateRepetValue(item, uId);
          }

          if (item.schedule.updateStatus && conCurrentDate === conLastDueDate) {
            stuffData.push(item);
          } else if (!item.schedule.updateStatus && conCurrentDate >= conLastDueDate) {
            stuffData.push(item);
          } else {
            // if ((currentDate < startDate && startDate) || (currentDate > endDate && endDate)) {
              // item.addVal = true;
            // }
            
            if (item.input.value && item.input.value !== 0 && item.input.value !== '0' && item.input.value !== '') {
              stuffData.push(item);
            } else {
              item.showDueOnDate = showDueOnDate;
              if(currentDate > endDate && endDate){
                stuffDataCompleted.push(item);                           
              }else{                  
                stuffDataFutcher.push(item);
              }
            }
          }
        }
      } else {
        stuffData.push(item);
      }
    });
   console.log('*************************', stuffDataFutcher); 
   console.log('------------------------------------------'); 
    const obj = {
      stuffDataNew: stuffData,
      stuffDataFutcherNew: stuffDataFutcher,
      stuffDataCompletedNew: stuffDataCompleted,
      status: true
    };
    return obj;
  }


  filteTrackerByDailyData(resp, stuffData, selectedDateInSeconds, constEnumFile) {
    resp.map((item: any, index) => {
      const stuffIndex = stuffData.findIndex((stuff: any) => stuff.id === item.itemid);
      if (stuffIndex !== -1) {
        if (item.date.seconds === selectedDateInSeconds) {
          stuffData[stuffIndex].lastValue = item && item.lastValue ? item.lastValue : '';
          stuffData[stuffIndex].input.value = item.type === 'removed' ? '' : item.value;
          stuffData[stuffIndex].input.note = item.type === 'removed' ? '' : item.note;
          stuffData[stuffIndex].valueId = item.type === 'removed' ? '' : item.docId;
          stuffData[stuffIndex].createdAt = item.type === 'removed' ? '' : item.createdAt;
          stuffData[stuffIndex].date = item.type === 'removed' ? '' : item.date;
          stuffData[stuffIndex].averageValue = item.type === 'removed' ? '' : item.averageValue;
          if (stuffData[stuffIndex].input.type === 'icon') {
            stuffData[stuffIndex].count = item.type === 'removed' ? '' : item.value;
          } else if (
            item && item.lastValue && (Number(item.lastValue) !== Number(stuffData[stuffIndex].input.value))) {
            if (stuffData[stuffIndex].summary &&
              stuffData[stuffIndex].summary.type === constEnumFile.differenceAverage) {
              stuffData[stuffIndex].differenceValue = (item && item.averageValue && stuffData[stuffIndex].input.value)
                ? Math.abs(item.averageValue - item.value)
                  .toFixed(stuffData[stuffIndex].input.decimal) : '';
              // tslint:disable-next-line: max-line-length
              stuffData[stuffIndex].differenceIcon = (item && item.averageValue && stuffData[stuffIndex].input.value) ? Number(item.averageValue) < Number(item.value) ? 'up' : 'down' : '';
            } else {
              stuffData[stuffIndex].differenceValue = (item && item.lastValue && stuffData[stuffIndex].input.value)
                ? Math.abs(item.lastValue - stuffData[stuffIndex].input.value)
                  .toFixed(stuffData[stuffIndex].input.decimal) : '';

              // tslint:disable-next-line: max-line-length
              stuffData[stuffIndex].differenceIcon = (stuffData[stuffIndex].input.value) ? Number(item.lastValue) > Number(stuffData[stuffIndex].input.value)
                ? 'down' : 'up' : '';
            }
          } else if (
            item && item.lastValue && (Number(item.lastValue) === Number(stuffData[stuffIndex].input.value))) {
            if (stuffData[stuffIndex].summary &&
              stuffData[stuffIndex].summary.type === constEnumFile.differenceAverage) {
              // tslint:disable-next-line: max-line-length
              stuffData[stuffIndex].differenceValue = (stuffData[stuffIndex].input.value) ? Math.abs(item.averageValue - stuffData[stuffIndex].item.value)
                .toFixed(stuffData[stuffIndex].input.decimal) : '';
            } else {
              // tslint:disable-next-line: max-line-length
              stuffData[stuffIndex].differenceValue = (stuffData[stuffIndex].input.value) ? Math.abs(item.lastValue - stuffData[stuffIndex].input.value)
                .toFixed(stuffData[stuffIndex].input.decimal) : '';
            }
          }
        }

      }
    });

    const obj = {
      stuffDataNew: stuffData,
    };

    this.console.log('return obj', obj);

    return obj;
  }


}
