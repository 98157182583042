import { Injectable } from '@angular/core';
import { ConstantVariables } from 'src/const/constant';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConsoleService } from 'src/app/services/console.service';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {

  constructor(
    private constVar: ConstantVariables,
    private angularFirestore: AngularFirestore,
    private console: ConsoleService,
  ) { }

  public addGoalsData(userDocId: any, data: any, docId: string): Observable<any> {
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(userDocId)
        .collection('goals').doc(docId).set(data)
        .then(async (response: any) => {
          observer.next({
            message: this.constVar.goalAddSuccess,
            status: 200,
            // id: response.id,
          });
        }).catch((error: any) => {
          observer.next({
            message: error.message ? error.message : this.constVar.someIssue,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public deleteGoals(docId: string, currentUserId: string): Observable<any> {
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(currentUserId).collection('goals')
        .doc(docId).delete().then(async () => {
          observer.next({
            message: this.constVar.goalsDeleted,
            status: 200,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : this.constVar.commonError,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public getLastGoalData(currentUserId: any): Observable<any> {
    const obj = new Observable((observer) => {
      let allData = [];
      this.angularFirestore.collection('users').doc(currentUserId)
        .collection('goals', ref =>
          ref.orderBy('sortBy', 'desc').limit(1)
        )
        .snapshotChanges().pipe().forEach(actions => {
          allData = [];
          actions.forEach(doc => {
            const docId = doc.payload.doc.id;
            const data: any = doc.payload.doc.data();
            allData.push({ docId, ...data });
          });
          observer.next({
            status: 200,
            message: this.constVar.getTodosListMsg,
            data: allData
          });
        }).catch((error) => {
          this.console.log(error);
          observer.next({
            message: error.message ? error.message : this.constVar.commonError,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obj;
  }


  public updateGoals(uId: string, docId: string, itemObj: any): Observable<any> {
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(uId)
        .collection('goals').doc(docId)
        .update(itemObj).then(() => {
          observer.next({
            message: this.constVar.goalsUpdatedSuccess,
            status: 200,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : this.constVar.commonError,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public getGoalsList(uid: any, limit: number): Observable<any> {
    return this.angularFirestore.collection('users')
      .doc(uid).collection('goals', ref => ref
        .orderBy('sortBy', 'desc').where('done', '==', false).limit(limit))
      .snapshotChanges()
      .pipe(map((actions) => {
        return actions.map(doc => {
          const data = doc.payload.doc.data();
          const id = doc.payload.doc.id;
          return { docId: id, ...data };
        });
      })
      );
  }

  public getGoalsDoneList(uid: any, currentDate: any, limit: number): Observable<any> {
    return this.angularFirestore.collection('users')
      .doc(uid).collection('goals', ref => ref
        .orderBy('sortBy', 'desc')
        .where('done', '==', true)
        .where('doneDate', '==', currentDate).limit(limit))
      .snapshotChanges()
      .pipe(map((actions) => {
        return actions.map(doc => {
          const data = doc.payload.doc.data();
          const id = doc.payload.doc.id;
          return { docId: id, ...data };
        });
      })
      );
  }

  public getGoalUndonTodos(currentUserId: any, goalId): Observable<any> {
    const obj = new Observable((observer) => {
      let allData = [];
      this.angularFirestore.collection('users').doc(currentUserId)
        .collection('todos', ref =>
          ref.where('goals.goalId', '==', goalId)
            .orderBy('sortBy', 'desc'))
        .snapshotChanges().pipe().forEach(actions => {
          allData = [];
          actions.forEach(doc => {
            const docId = doc.payload.doc.id;
            const data: any = doc.payload.doc.data();
            allData.push({ docId, ...data });
          });
          observer.next({
            status: 200,
            message: '',
            data: allData
          });
        }).catch((error) => {
          this.console.log(error);
          observer.next({
            message: error.message ? error.message : this.constVar.commonError,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obj;
  }
}
