import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {

  constructor() { }

  public log(...log: any): void {
    // console.log('About to print', ...log);

    if (!environment.production) {
      let err: any;
      try {
        throw new Error();
      } catch (error) {
        err = error;
      }
      try {
        const stacks = err.stack.replace(/\r/g, '').split(/\n/);;
        const fileInfo = stacks[2];
        console.group(fileInfo);
        console.log(...log);
        console.groupEnd();
      } catch (err) {
        console.log(...log);
      }
    }
  }

  public error(msg: any, res: any): void {
    if (!environment.production) { console.error(msg, res); }
  }
}
