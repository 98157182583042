import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchIconService {


  constructor(
    private http: HttpClient,
  ) { }

  public getIconsList(): Observable<any> {
    return this.http.get<any>(
      `/assets/mock-data/icons.json`,
    ).pipe(
      map((res: any) => {
        const iconsClass = [];
        const data = Object.keys(res);
        data.map((resp) => {
          const item = res[resp].styles.some((x: string) => x === 'solid');
          item ? iconsClass.push({ name: resp, iconsClass: `fas fa-${resp}` })
            : iconsClass.push({ name: resp, iconsClass: `fab fa-${resp}` });
        });
        return iconsClass;
      })
    );
  }
}
