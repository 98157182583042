import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import '@codetrix-studio/capacitor-google-auth';
import { Plugins } from '@capacitor/core';
import { ConstantVariables } from 'src/const/constant';
import {  DateService, AnalyticsService } from 'src/app/services';
import { AngularFireFunctions } from '@angular/fire/functions';
import 'firebase/firestore';
import 'firebase/auth';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { GlobalService } from 'src/app/services/global/global.service';

import { catchError ,take } from 'rxjs/operators';
import { ConsoleService } from 'src/app/services/console.service';


@Injectable({
  providedIn: 'root'
})
export class UserLoginService {

  public currentUser: any = null;
  public isMobile: boolean;
  infoCurrentUser: any;


  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private router: Router,
    private constantVariables: ConstantVariables,
    private globalService: GlobalService,
    private angularFireFunctions: AngularFireFunctions,
    private dateService: DateService,
    private datePipe: DatePipe,
    private analyticsService: AnalyticsService,
    private console: ConsoleService,
  ) {
    this.checkAuth();
  }

  public checkAuth(): Observable<any> {
    const obj = new Observable((observer) => {
      this.angularFireAuth.onAuthStateChanged(user => {
        if (user) {
          this.currentUser = user;
          observer.next({
            user: this.currentUser,
            status: 200,
          });

        } else {
          this.currentUser = null;
          observer.next({
            user: this.currentUser,
            status: 500,
          });
        }
      },
        error => {
          observer.next({
            message: error.message,
            status: 500,
          });
        });
    });
    return obj;
  }

  public logoutUser(): void {
    this.currentUser = null;
    localStorage.clear();
    this.globalService.userLoginStatus('logout');
    this.angularFireAuth.signOut();
    if (this.isMobile) {
      Plugins.GoogleAuth.signOut();
    }
    this.router.navigateByUrl('login', { replaceUrl: true });
  }

  public loginWithGoogleWeb(isMobile: boolean): Observable<any> {
    this.isMobile = isMobile;
    const obj = new Observable((observer) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      this.angularFireAuth.signInWithPopup(provider).then((result) => {
        this.currentUser = result.user;
        this.angularFireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            observer.next({
              user: this.currentUser,
              status: 200,
            });
          })
          .catch((error) => {
            this.console.log('Error loginWithGoogle', error);
            observer.next({
              message: error.message,
              status: 500,
            });
          });
      },
        error => {
          this.console.log('Error in signing up with Google', error);
          observer.next({
            message: error.message,
            status: 500,
          });
        }
      );
    });
    return obj;
  }

  public async loginWithGoogleApp(isMobile: boolean): Promise<any> {
    this.isMobile = isMobile;
    const googleUser = await Plugins.GoogleAuth.signIn();
    const credential = firebase.auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);
    return this.angularFireAuth.signInAndRetrieveDataWithCredential(credential).then(result => {
      this.currentUser = result.user;
      return this.angularFireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          return {
            user: this.currentUser,
            status: 200,
          };
        })
        .catch((error) => {
          this.console.log('Error loginWithGoogle');
          return {
            message: error.message,
            status: 500,
          };
        });
    }, (error: HttpErrorResponse) => {
      this.console.log('error', error);
    });
  }

  public async loginWithApple(isMobile: boolean, appleResponse: any): Promise<any> {
    this.console.log('About to login with Apple', appleResponse);

    this.isMobile = isMobile;

    // Create a custom OAuth provider
    const provider = new firebase.auth.OAuthProvider('apple.com');

    // Create sign in credentials with our token
    const credential = provider.credential({
      idToken: appleResponse.identityToken
    });

    // Call the sign in with our created credentials
    // const userCredential = await this.angularFireAuth.signInWithCredential(credential);

    return this.angularFireAuth.signInAndRetrieveDataWithCredential(credential).then(result => {
      this.currentUser = result.user;
      // this.updateLastEntry();
      return this.angularFireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          return {
            user: this.currentUser,
            status: 200,
          };
        })
        .catch((error) => {
          this.console.log('Error loginWithApple');
          return {
            message: error.message,
            status: 500,
          };
        });
    }, (error: HttpErrorResponse) => {
      this.console.log('error in httpResponse of loginWithApple', error);
    });
  }


  public loginWithEmail(email: any, isMobile: boolean, hostName: any): Observable<any> {
    this.isMobile = isMobile;
    let actionCodeSettings: any = {};
    const obj = new Observable((observer) => {
      if (isMobile) {
        actionCodeSettings = {
          url: `https://bettertoday.page.link/mobilelogin`,
          handleCodeInApp: true,
          iOS: {
            bundleId: 'app.bettertoday.app'
          },
          android: {
            packageName: 'app.bettertoday.app'
          },
          dynamicLinkDomain: 'bettertoday.page.link'
        };
      } else {
        actionCodeSettings = {
          url: `${hostName}`,
          // This must be true.
          handleCodeInApp: true
        };
      }
      window.localStorage.setItem('emailForSignIn', email);
      this.angularFireAuth.sendSignInLinkToEmail(email, actionCodeSettings).then((result: any) => {
        observer.next({
          message: 'Sign in link sent to your email at ' + email,
          data: email,
          status: 200,
        });
      },
        (error: HttpErrorResponse) => {
          observer.next({
            message: error.message,
            status: 500,
          });
        }
      );
    });
    return obj;
  }

  public updateUserData(user: any, value: any): Observable<any> {
    let data: any;
    if (value.userDelete) {
      data = { userDelete: value.userDelete };
    } else {
      data = { userType: value };
    }
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(user.uid)
        .update(data).then(async () => {
          // this.healthStuffService.addUserStuffProperties(user.uid);
          observer.next({
            status: 200,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : 'There is some issue...',
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public getUserData() {
    if (this.currentUser !== null) {
      const uid = this.currentUser.uid;
      return this.angularFirestore.collection
        ('users', ref => ref.where('docId', '==', uid))
        .snapshotChanges()
        .pipe(map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const id = doc.payload.doc.id;
            data.id = id;
            this.infoCurrentUser = { data, id };
            return { data, id };
          });
        })
        );
    } else {
      return of();
    }
  }

  public confirmSignIn(url: any): Observable<any> {
    const obj = new Observable((observer) => {
      const email = window.localStorage.getItem('emailForSignIn');
      this.angularFireAuth.signInWithEmailLink(email, url).then((result) => {
        this.currentUser = result.user;
        this.angularFireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            // this.updateLastEntry();
            window.localStorage.removeItem('emailForSignIn');
            observer.next({
              user: this.currentUser,
              status: 200,
            });
          })
          .catch((error) => {
            this.console.log('error', error);
            observer.next({
              message: error.message,
              status: 500,
            });
          });
      },
        error => {
          observer.next({
            message: error.message,
            status: 500,
          });
        }
      );
    });
    return obj;
  }

  public getQuotesByUserId(docId: any) {
    return this.angularFirestore.collection('users', ref => ref
      .where('docId', '==', docId)).snapshotChanges()
      .pipe(map((actions) => {
        return actions.map(doc => {
          const data = doc.payload.doc.data();
          const id = doc.payload.doc.id;
          return { data, id };
        });
      })
      );
  }

  UpdateUserTypeOnServerSide(data) {
    const newObs = new Observable((observer) => {
      const callable = this.angularFireFunctions.httpsCallable('updateUserTypeTo');
      const obs = callable({ allData: data });
      obs.subscribe(resp => {
        observer.next({
          status: 200,
          message: resp,
        });
      }, err => {
        this.console.log(err);
        observer.next({
          status: 500,
          message: 'Add tranctons error',
        });
      });

    });
    return newObs;

  }

  public addUserData(user: any, userData: any): Observable<any> {
    // const date = this.dateService.changeDateForMemory(this.constantVariables.useFreeUserDay);
    const fullNameText = user.email.split('@')[0];
    const fName = fullNameText.split('.')[0];
    const convertTest = fullNameText.split('.').slice(1).join();
    const lName = convertTest.replace(',', ' ');

    const data = {
      email: user.email,
      docId: user.uid,
      delayInQuotes: 15000,
      // nextTxnDate: new Date(date),
      firstName: fName,
      // userPackageType: 'premium trial',
      lastName: lName,
      userType: this.constantVariables.userType.premiumUsers,
      // txnDisplayDate: this.datePipe.transform(new Date(date), 'd MMM y'),
      // userType: 'premium trial',
      photoURL: user.providerData[0] ? user.providerData[0].photoURL : '',
      stats: {
        todos: 0,
        memories: 0,
        gratitudes: 0,
        tracker: 0,
        quotes: 0,
        trackerItems: [],
      }
    };
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(user.uid).set(data).then(async () => {
        observer.next({
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public updateUserType(): Observable<any> {
    const user = this.currentUser;
    const obs = new Observable((observer) => {
      // user.updateProfile({
      //   displayName: 'premiumUsers'
      // });
      this.angularFirestore.collection('users').doc(user.uid)
        .update({ premiumUsersDate: new Date() }).then(async () => {
          observer.next({
            status: 200,
            message: 'user updated successfully',
            data: user.displayName,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : 'There is some issue...',
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public addDefaultQuotes(uid: any, qut: any): Observable<any> {
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(uid)
        .collection('quotes').add(qut).then(async () => {
          observer.next({
            status: 200,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : 'There is some issue...',
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public getDefaultQuotes(): Observable<any> {
    return this.angularFirestore.collection('defaultQuotes')
      .snapshotChanges().pipe(
        map((actions) => {
          return actions.map(doc => {
            const data = doc.payload.doc.data();
            const id = doc.payload.doc.id;
            return { data, id };
          });
        })
      );
  }

  public async updateUserProfile(userData: any): Promise<any> {
    const user = this.currentUser;
    return await this.reAuthenticate().then(async () => {
      return user.updateEmail(userData.email).then(async () => {
        return this.angularFirestore.collection('users').doc(user.uid)
          .update(userData).then(async () => {
            return {
              status: 200,
              message: 'Profile updated successfully.',
              data: null
            };
          }, (error: HttpErrorResponse) => {
            return error;
          });
      }).catch((error: HttpErrorResponse) => {
        return error.message;
      });
    }).catch((error) => {
      return error.message;
    });
  }

  private async reAuthenticate(): Promise<any> {
    const user = this.currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email, user.providerData[0].providerId);
    return user.reauthenticateWithCredential(cred).then((resps: any) => {
      return resps;
    }, (error: HttpErrorResponse) => {
      return error;
    });
  }

  public async uploadUserImage(photo: any, next: any) {
    const currentUserId = this.currentUser.uid;
    const currentDate = new Date();
    const milliSecond = currentDate.getTime();
    const selfieRef = await firebase.storage().ref(`users/${currentUserId}/${milliSecond}`);
    // UPDLOAD USER PROFILE PHOTO IN FIREBASE STORAGE
    return selfieRef.putString(photo, 'base64', { contentType: 'image/jpeg' })
      .then(async savedProfilePhoto => {
        // GER DOWNLOAD URL FROM FIRBASE STORAGE OF PROFILE PHOTO
        return savedProfilePhoto.task.snapshot.ref.getDownloadURL().then(async downloadURL => {
          // UPDATE USER PROFILE PHOTO IN FIRBASE DATABASE
          photo = downloadURL;
          const data = {
            photoURL: downloadURL
          };
          if (downloadURL) {
            this.updateProfile(data, next).subscribe((res) => {
              // tslint:disable-next-line: no-unused-expression
              this.console.log('res', res);
            });
          }
        });
      });
  }

  private updateProfile(userData: any, next: any): Observable<any> {
    const user = this.currentUser;
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(user.uid)
        .update(userData).then(async () => {
          observer.next({
            status: 200,
            message: this.constantVariables.profileUpdateSuccess
          });
          next();
        }).catch((error: any) => {
          observer.next({
            message: error.message ? error.message : this.constantVariables.someIssue,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public deleteUser(): Observable<any> {
    const uid = this.currentUser.uid;
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(uid)
        // .delete().then(async (result) => {
        .delete().then((result) => {
          this.logoutUser();
          this.currentUser = null;
          this.angularFireAuth.signOut();
          this.router.navigateByUrl('login', { replaceUrl: true });
          // tslint:disable-next-line: no-unused-expression
          this.console.log('this.currentUser', this.currentUser);
          observer.next({
            status: 200,
            message: 'delete account',
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : 'There is some issue...',
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public getConfigData(): Observable<any> {
    return this.angularFirestore.collection('config')
      .snapshotChanges()
      .pipe(map((actions: any) => {
        return actions.map(doc => {
          const data = doc.payload.doc.data();
          const docId = doc.payload.doc.id;
          return { docId, ...data };
        });
      })
      );
  }

  public getCouponData(code: any): Observable<any> {
    return this.angularFirestore.collection('couponCodes', ref => ref
      .where('coupon', '==', code)).snapshotChanges()
      .pipe(map((actions: any) => {
        return actions.map(doc => {
          const data = doc.payload.doc.data();
          const docId = doc.payload.doc.id;
          return { docId, ...data };
        });
      })
      );
  }

  public addTranction(data: any) {
    const newObs = new Observable((observer) => {
      const callable = this.angularFireFunctions.httpsCallable('addTranctionTo');
      const obs = callable({ allData: data });
      obs.subscribe(resp => {
        if (resp === 'Payment process is successfuly done') {
          const user = this.currentUser;
          // user.updateProfile({
          //   displayName: 'premiumUsers'
          // });
          const userData = {
            userId: user.uid,
            userPackageType: this.constantVariables.userType.premiumUsers,
            userType: this.constantVariables.userType.premiumUsers,
          };
          this.UpdateUserTypeOnServerSide(userData).subscribe(res => {
          });
        }
        observer.next({
          status: 200,
          message: resp,
        });
      }, err => {
        this.console.log(err);
        observer.next({
          status: 500,
          message: 'Add tranctons error',
        });
      });
    });
    return newObs;
  }

  addFreeGoalAndTodos(userId) {
    const newObs = new Observable((observer) => {
      const callable = this.angularFireFunctions.httpsCallable('addFreeGoalTodosTo');
      const obs = callable({ allData: userId });
      obs.subscribe(resp => {
        observer.next({
          status: 200,
          message: resp,
        });
      }, err => {
        this.console.log(err);
        observer.next({
          status: 500,
          message: '',
        });
      });
    });
    return newObs;

  }

  public getUserTranctions(user): Observable<any> {
    return this.angularFirestore.collection('transactions', ref => ref
      .where('userId', '==', user.docId).orderBy('txnDateTime', 'desc').limit(1)).snapshotChanges()
      .pipe(map((actions: any) => {
        return actions.map(doc => {
          const data = doc.payload.doc.data();
          const docId = doc.payload.doc.id;
          return { docId, ...data };
        });
      })
      );
  }

  public checkAppStoreReceiptExist(user, appStoreReceipt): Observable<any> {
    this.console.log(`3000 Checking if the app store receipt of this user exists`, appStoreReceipt)
    return this.angularFirestore.collection('transactions', ref => ref
      // tslint:disable-next-line:max-line-length
      .where('userId', '==', user.docId).where('appStoreReceipt', '==', appStoreReceipt ).limit(1)).snapshotChanges()
      .pipe(take(1), map((actions: any) => {
        return actions.map(doc => {
          const data = doc.payload.doc.data();
          const docId = doc.payload.doc.id;
          return { docId, ...data };
        });
      })
      );
  }

}
