import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TimerService {

  public timerEvent = new BehaviorSubject(null);
  public timerEventData = this.timerEvent.asObservable();

  public interval = new BehaviorSubject(null);
  public intervalData = this.interval.asObservable();

  constructor(
  ) { }

  public timerEventHandler(event: any) {
    this.timerEvent.next(event);
  }

  public intervalHandler(event: any) {
    this.timerEvent.next(event);
  }

  public convertIntoTime(timeInterval: string): void {
    const secArr = timeInterval.split(':');
    let hours: any;
    let sec: any;
    let min: any;
    if (secArr.length === 1) {
      sec = timeInterval;
      hours = Math.floor(sec / 3600);
      if (hours >= 1) {
        sec = sec - (hours * 3600);
      }
      min = Math.floor(sec / 60);
      if (min >= 1) {
        sec = sec - (min * 60);
      }
      if (sec < 1) {
        sec = '0';
      }
    } else if (secArr.length === 2) {
      min = Math.floor(Number(secArr[1]) / 60);
      if (min >= 1) {
        sec = Number(Number(secArr[1]) - (min * 60));
        secArr[0] = Number(secArr[0]) + min;
      } else {
        sec = secArr[1];
      }
      hours = Math.floor(Number(secArr[0]) / 60);
      if (hours >= 1) {
        min = (Number(secArr[0]) - (hours * 60));
      } else {
        min = secArr[0];
      }
    } else if (secArr.length === 3) {
      min = Math.floor(Number(secArr[2]) / 60);
      if (min >= 1) {
        sec = (Number(secArr[2]) - (min * 60));
        secArr[1] = Number(secArr[1]) + min;
      } else {
        sec = secArr[2];
      }
      hours = Math.floor(Number(secArr[1]) / 60);
      if (hours >= 1) {
        min = (Number(secArr[1]) - (hours * 60));
      } else {
        min = secArr[1];
      }
      if (hours >= 1) {
        hours = (Number(secArr[0]) + hours);
      } else {
        hours = secArr[0];
      }
    }
    if (hours.toString().length === 1) {
      hours = '0' + hours;
    }
    if (min.toString().length === 1) {
      min = '0' + min;
    }
    if (sec.toString().length === 1) {
      sec = '0' + sec;
    }
    let time: any;
    if (hours !== '00') {
      time = hours + ':' + min + ':' + sec;
    } else if (min !== '00') {
      time = '00' + ':' + min + ':' + sec;
    } else {
      time = '00' + ':00' + ':' + sec;
    }
    return time;
  }
}
