import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TickerService {
  nowEpoch: number;

  constructor() { }

  // Reference: https://stackoverflow.com/questions/29971898/how-to-create-an-accurate-timer-in-javascript
  public startTimer(): Observable<any> {
    this.nowEpoch = Date.now();
    const timeout$: Observable<any> = this.getElapsedTime$();
    return timeout$;
  }

  private getElapsedTime$(): Observable<any> {
    return timer(0, 1000)
      .pipe(
        map(() => this.getElapsedSeconds()),
        map(elapsedSeconds => {
          elapsedSeconds = Math.floor(elapsedSeconds / 1000);
          return elapsedSeconds;
        })
      );
  }

  public convertTimeToSeconds(timeFormat: any): number {
    const [hh = '0', mm = '0', ss = '0'] = (timeFormat || '0:0:0').split(':');
    const hour = parseInt(hh, 10) || 0;
    const minute = parseInt(mm, 10) || 0;
    const second = parseInt(ss, 10) || 0;
    const timeInSeconds = (hour * 3600) + (minute * 60) + (second);
    return timeInSeconds;
  }

  private getElapsedSeconds(): number {
    return Date.now() - this.nowEpoch;
  }

  public updateNowEpoch(): void {
    this.nowEpoch = Date.now();
  }

  public convertIntoTime(timeInterval: string): void {
    const secArr = timeInterval.split(':');
    let hours: any;
    let sec: any;
    let min: any;
    if (secArr.length === 1) {
      sec = timeInterval;
      hours = Math.floor(sec / 3600);
      if (hours >= 1) {
        sec = sec - (hours * 3600);
      }
      min = Math.floor(sec / 60);
      if (min >= 1) {
        sec = sec - (min * 60);
      }
      if (sec < 1) {
        sec = '0';
      }
    } else if (secArr.length === 2) {
      min = Math.floor(Number(secArr[1]) / 60);
      if (min >= 1) {
        sec = Number(Number(secArr[1]) - (min * 60));
        secArr[0] = Number(secArr[0]) + min;
      } else {
        sec = secArr[1];
      }
      hours = Math.floor(Number(secArr[0]) / 60);
      if (hours >= 1) {
        min = (Number(secArr[0]) - (hours * 60));
      } else {
        min = secArr[0];
      }
    } else if (secArr.length === 3) {
      min = Math.floor(Number(secArr[2]) / 60);
      if (min >= 1) {
        sec = (Number(secArr[2]) - (min * 60));
        secArr[1] = Number(secArr[1]) + min;
      } else {
        sec = secArr[2];
      }
      hours = Math.floor(Number(secArr[1]) / 60);
      if (hours >= 1) {
        min = (Number(secArr[1]) - (hours * 60));
      } else {
        min = secArr[1];
      }
      if (hours >= 1) {
        hours = (Number(secArr[0]) + hours);
      } else {
        hours = secArr[0];
      }
    }
    if (hours.toString().length === 1) {
      hours = '0' + hours;
    }
    if (min.toString().length === 1) {
      min = '0' + min;
    }
    if (sec.toString().length === 1) {
      sec = '0' + sec;
    }
    let time: any;
    if (hours !== '00') {
      time = hours + ':' + min + ':' + sec;
    } else if (min !== '00') {
      time = '00' + ':' + min + ':' + sec;
    } else {
      time = '00' + ':00' + ':' + sec;
    }
    return time;
  }
}
