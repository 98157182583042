<ion-app>
<!-- <ion-app *ngIf="mobileMode"> -->
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" *ngIf="userInfo">
      <ion-content>
        <ion-row class="ion-margin-top top-row">
          <ion-col class="ion-text-center ion-no-padding">
            <ion-list class="ion-no-margin">
              <ion-item lines="none" class="ion-text-center user-pic">
                
                <ion-menu-toggle class="img-toggal">
                  <ion-avatar class="ion-no-margin" (click)="goToProfile()">
                    <ion-img [src]="userProfileData && userProfileData.photoURL 
                      ? userProfileData.photoURL : defaultUserPic"></ion-img>
                  </ion-avatar>
                </ion-menu-toggle>

                <ion-buttons slot="end" class="ion-no-margin ion-no-padding" mode="ios">
                  <ion-menu-button color="medium" class="ion-no-margin ion-no-padding">
                    <i class="fas fa-bars"></i>
                  </ion-menu-button>
                </ion-buttons>
              </ion-item>

              <ion-item lines="none" class="ion-text-center user-name">
                <ion-label class="ion-no-margin">
                  <h2 class="ion-no-margin" *ngIf="userProfileData">
                    {{userProfileData.firstName}} {{userProfileData.lastName}}
                  </h2>
                  <!-- <h2 class="ion-no-margin">{{userProfileData ? userProfileData.fullName : ''}}</h2> -->
                  <p class="ion-no-margin">
                    <i class="fas fa-crown" *ngIf = "userType === 'Upgrade to Pro'"></i>
                    <span (click)="openUpgradeModel()">{{userType}}</span>
                  </p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-list id="inbox-list" class="ion-no-margin">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index; trackBy:trackById">
            <ion-item  
              routerDirection="root" 
              lines="none"
              detail="false" 
              (click)="navigateBySidemenu(p.url); selectedIndex = i"
              [class.selected]="selectedIndex == i"
            > <i *ngIf="p.title !== 'Help'; else todoList" [class]="p.icon" ></i>
              <ng-template #todoList>
                <ion-icon slot="start" class="help-icon" name="help-circle-outline"></ion-icon>
              </ng-template>
              <ion-label>{{ p.title }}</ion-label>
              <ion-label class="ion-text-end number-label">{{ p.number }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-row class="ion-margin-top last_row">
          <ion-col>
            <ion-list class="better-list ion-no-padding">
              <ion-item lines="none">
                <ion-label>
                  <span>Better</span>
                  <span>Today</span>
                  <p (click)="openVersionInfoModel()">ver {{constVar.appVersion}}</p>
                </ion-label>
              </ion-item>
            </ion-list>
            <ion-list class="email-list ion-no-padding">
              <ion-item lines="none">
                <ion-label>
                  <span>We would love to hear from you </span>
                  <p class="click-email"><a href="mailto:gunjan@bettertoday.app?subject=Hi%20from%20Better%20Today%20app" target="_blank">gunjan@bettertoday.app</a></p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-content>
    </ion-menu>

    <div class="top-div" *ngIf="!userInfo">
      <div class="mid-div">
        <div>
          <!-- <span class="loading">Loading</span> -->
          <img src="/assets/images/logo.png" alt="Better Today Logo" class="logo-img">
        </div>
        <div class="logo-text">
          <span class="better">
            BETTER
          </span>
          <span class="today">
            TODAY
          </span>
        </div>
        <!-- <img src="/assets/images/loading-buffering.gif" alt="Better Today Logo" class="loding-img"> -->
        <div class="by-quadralyst">
          <span class="by">
            by
          </span> <br>
          <span class="quadralyst">
            Quadralyst
          </span>
        </div>
    
      </div>
    </div>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>