import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import { TickerService } from '../ticker-service/ticker.service';
import SimpleDateFormat from '@riversun/simple-date-format';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  // private todayDate = null;

  constructor(
    private datePipe: DatePipe,
    private tickerService: TickerService,
  ) { }

  public changeDateForMemory(days: number = 0): string {
    const prepareDate = new Date();
    prepareDate.setDate(prepareDate.getDate() + days);
    // this.todayDate = new Date();
    // this.todayDate = this.todayDate.addDays(days);
    let monthNum = '' + (prepareDate.getMonth() + 1);
    if (monthNum.length < 2) {
      monthNum = '0' + monthNum;
    }
    let dayNum = '' + (prepareDate.getDate());
    if (dayNum.length < 2) {
      dayNum = '0' + dayNum;
    }
    let hourNum = '' + (prepareDate.getHours());
    if (hourNum.length < 2) {
      hourNum = '0' + hourNum;
    }
    let minNum = '' + (prepareDate.getMinutes());
    if (minNum.length < 2) {
      minNum = '0' + minNum;
    }
    let secNum = '' + (prepareDate.getSeconds());
    if (secNum.length < 2) {
      secNum = '0' + secNum;
    }
    const currentDateKey = `${prepareDate.getFullYear()}-${monthNum}-${dayNum}`;
    return (currentDateKey);
  }

  public dateTimeFormateForMonth(date: any): string {
    if (date === '') {
      return date;
    } else {
      return date = date ?
        this.datePipe.transform(date, 'd MMM y') : date;
    }
  }

  public dateTimeFormate(date: any): string {
    if (date === '') {
      return date;
    } else {
      return date = date ?
        this.datePipe.transform(date * 1000, 'd MMM y') : date;
    }
  }

  public convertedDate(date: any): number {
    // tslint:disable-next-line: no-bitwise
    return date.getTime() / 1000 | 0;
  }

  public getOnlyDate(date: Date): string {
    let monthNum = '' + (date.getMonth() + 1);
    if (monthNum.length < 2) { monthNum = '0' + monthNum; }

    let dayNum = '' + (date.getDate());
    if (dayNum.length < 2) { dayNum = '0' + dayNum; }
    return `${date.getFullYear()}-${monthNum}-${dayNum}`;
  }

  public changeDateView(value: any) {
    return value = value ? this.datePipe.transform(value, 'MMM d, y') : value;
  }

  public setTimeFormat(currentDate: Date): Date {
    const date = new Date(currentDate);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  public convertDateIntoDays(date2: any, status?: boolean) {
    const dateB = DateTime.fromISO(date2).toRelativeCalendar();
    if (dateB === 'today') {
      const date = DateTime.fromISO(date2).toFormat('h:mm a');
      return status ? date : `at ${date}`;
    } else if (dateB === 'tomorrow') {
      const dateTom = DateTime.fromISO(date2).toFormat('h:mm a');
      return `tom ${dateTom}`;
    } else {
      const dateAftTom = DateTime.fromISO(date2).toFormat('LLL dd, h:mm a');
      return status ? dateAftTom : `on ${dateAftTom}`;
    }
  }

  public checkDateIslessThanToday(value: any) {
    const w = DateTime.local().toSeconds();
    const x = DateTime.fromISO(value).toSeconds();
    const dateStatus = x < w ? true : false;
    return dateStatus;
  }

  public dateForIonDate(value: any) {
    return this.datePipe.transform(value, 'y-MM-dd');
  }

  public getDateFromSecond(value: any) {
    return value.date && value.date.seconds
      ? this.datePipe.transform(value.date.seconds * 1000)
      : this.datePipe.transform(value.date * 1000);
  }

  public convertForNotificaton(value: any) {
    return this.datePipe.transform(value, 'h:mm a');
  }

  public convertExpectedTimeToHours(timeArray: any) {
    let seconds: number = 0;
    timeArray.forEach((element: any) => {
      const a = (element + '').split(":");
      const sec = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      seconds = seconds + sec;
    });

    const hours = Math.floor(seconds / (60 * 60));
    const divisor_for_minutes = seconds % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    // const divisor_for_seconds = divisor_for_minutes % 60;
    // const seconds = Math.ceil(divisor_for_seconds);
    const prepareOutput = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
    return prepareOutput;
  }

  public convertNumberIntoHHMM(value: any) {
    if (value) {
      let min = null;
      const splitNumber = value.split(':');
      if (splitNumber[1]) {
        const prepareValue = `${splitNumber[0]}:${splitNumber[1]}:${splitNumber[2] ? splitNumber[2] : '00'}`;
        const convertedValue: any = this.tickerService.convertIntoTime(prepareValue);
        const splitValue2 = convertedValue.split(':');
        return `${splitValue2[0]}:${splitValue2[1]}`;
      } else {
        min = splitNumber[0];
        let h: any;
        let m: any;
        const hours = Math.floor(min / (60 * 60));
        const divisorForMinutes = min % (60 * 60);
        const minutes = Math.floor(divisorForMinutes / 60);
        h = hours < 10 ? '0' + hours : hours;
        m = minutes < 10 ? '0' + minutes : minutes;
        return `${h}:${m}`;
      }
    }
  }


  public convertNumberIntoHHMMSS(timeSpent: any) {
    
    if (timeSpent) {    
      const value = timeSpent.split(':');              
      let timeNew;
      if (value.length === 1) {      
        timeNew = Number(value[0]);
      }
      if (value.length === 2) {
        timeNew = (Number(value[0]) * 60 ) + Number(value[1]);
      }
      if (value.length === 3) {                           
        timeNew = (Number(value[0]) * 60 * 60 ) + (Number(value[1]) * 60 ) + Number(value[2]);
      } 

      let sec = null;
      const splitNumber = String(timeNew).split(':');
      if (splitNumber[1]) {        
        const prepareValue = `${splitNumber[0]}:${splitNumber[1]}:${splitNumber[2] ? splitNumber[2] : '00'}`;
        const convertedValue: any = this.tickerService.convertIntoTime(prepareValue);
        const splitValue2 = convertedValue.split(':');
        return `${splitValue2[0]}:${splitValue2[1]}`;
      } else {
        sec = splitNumber[0];
        let h: any;
        let m: any;
        let s: any;       
        const hours = Math.floor(sec / (60 * 60));        
        const divisorForMinutes = sec % (60 * 60);
        const minutes = Math.floor(divisorForMinutes / 60);
        const seccond = divisorForMinutes % (60);
        h = hours < 10 ? '0' + hours : hours;
        m = minutes < 10 ? '0' + minutes : minutes;        
        s = seccond < 10 ? '0' + seccond : seccond;
        return `${h}:${m}:${s}`;
      }      
    }
  }



  public changeFormatYYYMMDD(date: any) {
    return this.datePipe.transform(date, 'YYY-MM-dd');
  }


  public changeFormatHHMMAMPM(date: any) {
    return this.datePipe.transform(new Date(date), 'hh:mm a');
    // return this.datePipe.transform(new Date(date), 'HH:mm a');
  }

  public changeTimeStampToDateString(date: any) {
    const convertTime = new Date(date.seconds * 1000).getTime();
    const val = new Date(convertTime);
    return val;
  }

  public settTimeEmpty(date: any) {
    const convertTime = new Date(date.seconds * 1000).getTime();
    const val = new Date(convertTime).setHours(0, 0, 0);
    return val;
  }

  public getNextDayOfWeek(date: any, dayOfWeek: any) {
    const resultDate = new Date(date.getTime());
    resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    return resultDate;
    // return this.dateService.dateForIonDate(resultDate);
  }

  public changeDateFormatToIonic(value: any) {
    const sdf = new SimpleDateFormat();
    return sdf.formatWith('yyyy-MM-dd\'T\'HH:mm:ssXXX', value);
    // return this.datePipe.transform(value, 'yyyy-MM-dd\'T\'HH:mm:ssXXX');
  }





}
