import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { ConstantVariables } from '../const/constant';
import * as firebase from 'firebase';
import { VersionInfoComponent } from './version-info/version-info.component';
import {
  AngularFireAnalyticsModule,
  UserTrackingService,
  ScreenTrackingService
} from '@angular/fire/analytics';
const app = firebase.initializeApp(environment.firebase);
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
@NgModule({
  declarations: [
    AppComponent,
    VersionInfoComponent,
  ],
  entryComponents: [
    VersionInfoComponent
  ],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    AngularFireAnalyticsModule,
  ],
  providers: [
    StatusBar,
    DatePipe,
    SplashScreen,
    ConstantVariables,
    UserTrackingService,
    ScreenTrackingService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: SETTINGS,
      useValue: environment.emulators ? {
        host: 'localhost:8080',
        ssl: false
      } : undefined
    },
    InAppPurchase2,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    if (environment.emulators) {
      app.functions().useFunctionsEmulator('http://localhost:5001');
    }
  }
}
