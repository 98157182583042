<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button size="small" color="danger" (click)="dismissModel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
      <ion-card class="ion-no-margin">
        <ion-card-content class="user-name">
          <ion-item lines="none" class="ion-no-padding">
            <i class="fas fa-file-image" *ngIf="!ionIcon"></i>
            <ion-icon name="{{ionIcon}}" *ngIf="ionIcon"></ion-icon>
            <ion-label class="ion-no-margin">
              <p class="ion-no-margin">
                <i class="{{titleIcon}}"></i>
                <span>{{headerTitle}}</span>
              </p>
              <p class="ion-no-margin">{{headerSubTitle}}</p>
            </ion-label>
          </ion-item>
        </ion-card-content>
      </ion-card>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-no-padding ion-no-margin">
    <ion-row class="main_row_content">
      <ion-col>
        <ion-slides [options]="slideOpts" slide="left" *ngIf="slideOpts && slideImages">
          <ion-slide *ngFor="let slide of slideImages; trackBy:trackById">
            <div class="container-background" [style.backgroundImage]="'url('+ slide.image +')'">
              <p class="bottom-left" [innerHTML]="slide.title"></p>
            </div>
          </ion-slide>
        </ion-slides>
      </ion-col>
    </ion-row>

    <ion-row class="select_payment_row">
      <ion-col size="6" class="pay-section ion-no-padding ion-no-margin" 
        [class.selected-border]="selectAmout === 1">
        <ion-row class="margin_bottom cursor_pointer" (click)="checkPlateform ? purchase(products[1], 1) : selectPayAmount(1)" >
          <ion-col class="pay_yearly_box">
            <span [class.hideIcon]="selectAmout !== 1">
              <i class="fa fa-check-circle active_icon" aria-hidden="true"></i>
            </span>
            <ion-row class="contain-row">
              <ion-col size="7">
                <p class="ion-no-margin ion-no-padding pay-yearly">Pay Yearly</p>
                <p class="ion-no-margin ion-no-padding pay_money_label"  *ngIf="!checkPlateform">US$ {{yearlyAmount}}/year</p>
                <p class="ion-no-margin ion-no-padding pay_money_label"  *ngIf="checkPlateform">{{products[1].price}}</p>
              </ion-col>
              <ion-col size="5" class="save_box">
                <ion-row>
                  <ion-col class="save_amount_box">
                    <p *ngIf="!checkPlateform">Save {{savePercent}}%</p>
                    <p *ngIf="checkPlateform">Save {{inAppAnulaDis}}%</p>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-col>

      <ion-col size="5" class="pay-section" offset="1" 
        [class.selected-border]="selectAmout === 2">
        <!-- <ion-row class="margin_bottom cursor_pointer"
          (click)="selectPayAmount(2)"> -->
        <ion-row class="margin_bottom cursor_pointer" 
          (click)="checkPlateform ? purchase(products[0], 2) : selectPayAmount(2)">
          <ion-col class="pay_yearly_box">
            <span [class.hideIcon]="selectAmout !== 2">
              <i class="fa fa-check-circle active_icon" aria-hidden="true"></i>
            </span>
            <ion-row class="contain-row">
              <ion-col>
                <p class="ion-no-margin ion-no-padding pay-yearly">Pay Monthly</p>
                <p class="ion-no-margin ion-no-padding pay_money_label" *ngIf="!checkPlateform">US$ {{monthlyAmount}}/month</p>
                <p class="ion-no-margin ion-no-padding pay_money_label" *ngIf="checkPlateform">{{ products[0].price }}</p>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        
      </ion-col>
  
    </ion-row>

    <!-- <ion-row *ngIf="checkPlateform">
      <ion-col>
        <ion-list>
          <ion-item button *ngFor="let item of products" 
            (click)="purchase(item)" detail="false">
            <ion-label class="ion-text-wrap">
              {{ item.title }}
              <p>{{ item.description }}</p>
            </ion-label>
            <ion-button slot="end">
              {{ item.price }} {{ item.currency }}
            </ion-button>
          </ion-item>
        </ion-list>
         -->
        <!-- <ion-button expand="full" (click)="restore()">Restore</ion-button> -->
        
        <!-- <ion-item>
          Gems: {{ gems }}
        </ion-item>
        <ion-item>
          Is Pro: {{ isPro }}
        </ion-item> -->
      <!-- </ion-col>
    </ion-row> -->



    <ion-row class="coupon_code_row">
      <ion-col size="8">
        <ion-input placeholder="Coupon code" 
          class="new-input" 
          (ionBlur123)="checkCoupon()"
          [(ngModel)]="copenCode" 
          (ionChange)="checkCoupanText()"
        ></ion-input>
      </ion-col>
      <ion-col size="3" class="ion-text-end apply_button_col">
        <ion-button class="apply-btn" 
          (click)="checkCoupon()" 
          [disabled]='!applyBtnStatus'
        >Apply
        </ion-button>
      </ion-col>

      <ion-col class="coupon_msg_col" size="12" *ngIf="errorMsg ||succsMsg">
        <span class="errorMsg" *ngIf="errorMsg">{{errorMsg}}</span>
        <span class="succsMsg" *ngIf="succsMsg">{{succsMsg}}</span>
      </ion-col>
      <ion-col class="amount_col" size="12">
        <span *ngIf="!checkPlateform">Amount to be paid: ${{payAmount}}</span>
        
        <span *ngIf="checkPlateform && alradyOwend">Subscribed to {{selectedProduct.id}} subscription </span>
        <span *ngIf="checkPlateform && payAmount !== 0  && !alradyOwend">Amount to be paid: {{selectedProduct.price}}</span>
        <span *ngIf="checkPlateform && payAmount === 0">Upgrade is free</span>
      </ion-col>
    </ion-row>

    <!-- <ion-row class="comming-soon" *ngIf="checkPlateform">
      <ion-col class="ion-text-center">
        <p>Coming soon</p>
      </ion-col>
    </ion-row> -->
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-row>
    <ion-col size="4" *ngIf="checkPlateform && !alradyOwend">
      <ion-button
        class="custom-button"
        expand="block"
        [disabled]="clikToUpgrade"
        (click)="restore()"
      ><span>Refresh</span>
      </ion-button>
    </ion-col>
    
    <ion-col class="ion-text-right" *ngIf="!alradyOwend">
      <ion-button
        class="custom-button" 
        [disabled]="!applyStatus || clikToUpgrade"
        expand="block" 
        (click)="checkPlateform ? upgradeUserInApp() : upgradeUser()"
      >
      <span>Upgrade to premium</span>
        <ion-spinner name="crescent" 
          class="loding" 
          *ngIf="loading || clikToUpgrade"
        ></ion-spinner>
      </ion-button>
    </ion-col>


    <ion-col class="ion-text-right" *ngIf="alradyOwend">
      <ion-button
        class="custom-button"
        expand="block" 
        (click)="dismissModel()"
      >
      <span>Dissmis</span>
      </ion-button>
    </ion-col>


  </ion-row>
</ion-footer>
