import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.scss'],
})
export class ActionModalComponent implements OnInit {

  img: any;
  message: any;
  crown: any;
  constructor(
    private popoverCtr: PopoverController,
    private navParams: NavParams,
    private router: Router,
  ) { }
  ngOnInit() {
  }

  dismissPopover() {
    this.popoverCtr.dismiss();
  }

}

