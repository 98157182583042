import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor() { }

  public isIos() {
    if (Capacitor.getPlatform() === 'ios') {
      return true;
    } else {
      return false;
    }
  }
}
