import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Platform, ModalController, AlertController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UpgradeModelComponent } from './shared-ui-component/upgrade-model/upgrade-model.component';
import { Router, NavigationEnd } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { ToastService, AnalyticsService, LoadingService } from './services';
import { ConstantVariables } from 'src/const/constant';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { VersionInfoComponent } from './version-info/version-info.component';
const { App, Browser, Share, SplashScreen, LocalNotifications } = Plugins;
import { GlobalService } from './services/global/global.service';
import { UserLoginService } from './api-service/user-login/user-login.service';
import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2/ngx';
import { InAppService } from './api-service/in-app.service';
import { ConsoleService } from './services/console.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  public appPages = [
    {
      id: 1,
      title: 'Home',
      url: 'dashboard',
      icon: 'fas fa-home',
    },
    {
      id: 2,
      title: 'Todos',
      url: 'todos',
      number: 0,
      icon: 'fas fa-list'
    },
    {
      id: 3,
      title: 'Goals',
      url: 'goals',
      number: 0,
      icon: 'fas fa-bullseye'
    },
    {
      id: 4,
      title: 'Memories',
      url: 'memories',
      number: 0,
      icon: 'fas fa-calendar-alt'
    },
    {
      id: 5,
      title: 'Gratitudes',
      url: 'gratitude',
      number: 0,
      icon: 'fas fa-heart'
    },
    {
      id: 6,
      title: 'Tracking',
      url: 'healthstuff',
      number: 0,
      icon: 'fas fa-chart-line'
    },
    {
      id: 7,
      title: 'Bookmarks',
      url: 'bookmark',
      number: 0,
      icon: 'fas fa-bookmark'
    },
    {
      id: 8,
      title: 'Profile',
      url: 'upgrade-profile',
      icon: 'fas fa-user'
    },
    {
      id: 9,
      title: 'Share',
      url: 'share',
      icon: 'fas fa-share-alt'
    },
    {
      id: 10,
      title: 'Help',
      url: 'help',
      icon: 'ios-help-circle-outline'
    }
  ];
  public selectedIndex = 0;
  public userInfo: any;
  public userType: any;
  public userProfileData: any;
  public defaultUserPic = '/assets/images/avatar.png';

  private subscriptions: Subscription[] = [];
  private webUrl: any;
  oldTranctions = false;
  // public products: IAPProduct[] = [];
  // public gems = 0;
  // public isPro = false;

  constructor(
    public constVar: ConstantVariables,
    private platform: Platform,
    private statusBar: StatusBar,
    private userLoginService: UserLoginService,
    private modalController: ModalController,
    private router: Router,
    private globalService: GlobalService,
    private toastService: ToastService,
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private console: ConsoleService,

    // private store: InAppPurchase2,
    private alertController: AlertController,
    private inAppService: InAppService,
    private loadingService: LoadingService,

  ) {
    // this.loadingService.showLoading();
    this.initializeApp();
  }

  public initializeApp(): void {
    this.platform.ready().then(() => {
      SplashScreen.hide();
      // This disables back button action on Android
      this.platform.backButton.subscribeWithPriority(9999, () => {
        document.addEventListener('backbutton', (event) => {
          event.preventDefault();
          event.stopPropagation();
        }, false);
      });
    });
    this.setBrowserTitle();
    // this.createInApp();
    this.inAppService.createInApp();
  }

  public ngOnInit(): void {
    this.globalService.changeProUser.subscribe(res => {
      if (res) {
        this.userType = res === this.constVar.userType.freeUser ? 'Upgrade to Pro' : 'Premium User';
      }
    });
    this.subscribeData();
    this.getUserData();
    this.showNotification();
  }

  public ionViewDidLeave(): void {
    this.subscriptions.forEach((sub) => {
      if (sub && !sub.closed) {
        sub.unsubscribe();
      }
    });
  }

  public navigateBySidemenu(url: any): void {
    if (url === 'help') {
      // this.openBrowser('https://bettertoday.app/help/');
      this.goToHelp();
    } else if (url === 'share') {
      this.openSharePopup();
    } else {
      this.router.navigate([url]);
    }
  }

  goToProfile() {
    this.router.navigate(['upgrade-profile']);
  }


  private async goToHelp() {
    this.analyticsService.logEvent(this.constVar.analytics.tutorial, { location: 'sideMenu' });
    this.globalService.showHelpPopover('sideMenu');
  }

  private async openBrowser(link: string): Promise<any> {
    await Browser.open({ url: link });
  }

  public async openUpgradeModel() {
    // if (this.userProfileData.userType !== this.constVar.userType.premiumUsers) {
    if (this.oldTranctions || this.userProfileData.userType === this.constVar.userType.freeUser) {

      this.analyticsService.logEvent(this.constVar.analytics.upgradeStart, { value: 'menu' });
      const upgradeModel = await this.modalController.create({
        component: UpgradeModelComponent,
        componentProps: {
          titleIcon: 'fas fa-crown',
          headerTitle: this.constVar.fullUpdrateTitle,
          headerSubTitle: this.constVar.fullUpdrateSubTitle,
        },
        showBackdrop: true,
        backdropDismiss: false,
      });
      upgradeModel.onDidDismiss()
        .then((data) => {
          if (data.data && data.data.status === 200) {
            this.userType = this.constVar.userType.premiumUsers;
            this.globalService.profileUpdate(this.constVar.userType.premiumUsers);
            this.changeDetectorRef.detectChanges();
          }
        });
      return await upgradeModel.present();
    }
    // }
  }

  private getUserData(): void {
    this.subscriptions.push(
      this.userLoginService.checkAuth().subscribe(responseData => {
        if (responseData.status === 200) {
          this.userInfo = responseData.user;
          this.changeDetectorRef.detectChanges();
          this.getUserInfo();
          this.loadingService.hideLoading();
        } else {
          this.userInfo = null;
          this.loadingService.hideLoading();
        }
      }, (error: HttpErrorResponse) => {
        this.loadingService.hideLoading();
        this.userInfo = null;
        this.toastService.presentToast
          (this.constVar.errorMsg, this.constVar.errorcolor);
      }),
    );
  }

  private prepareSideMenuCount(title: string, value: number) {
    this.appPages.map((item) => {
      if (item.title.toLowerCase() === title) {
        item.number = value;
      } else if ((item.title.toLowerCase() === 'tracking') && (title === 'tracker')) {
        item.number = value;
      } else if ((item.title.toLowerCase() === 'bookmarks') && (title === 'bookmark')) {
        item.number = value;
      }
    });
  }

  private getUserInfo(): void {
    this.subscriptions.push(
      this.userLoginService.getUserData().subscribe((responseData: any) => {
        // tslint:disable-next-line: no-unused-expression
        // !environment.production && this.console.log('iam subscribe', responseData);
        if (responseData.length) {
          this.userProfileData = responseData[0].data;
          this.userType = this.userProfileData.userType === this.constVar.userType.premiumUsers ?
            // tslint:disable-next-line: max-line-length
            'Premium User' : this.userProfileData.userType === this.constVar.userType.freeUser ? 'Upgrade to Pro' : this.constVar.userType.premiumUsers;
          if (this.userProfileData && this.userProfileData.stats !== undefined) {
            const allStates = Object.keys(this.userProfileData.stats);
            const allStatesValues: any = Object.values(this.userProfileData.stats);
            if (allStates.length) {
              allStates.forEach((vals: any, keys: any) => {
                this.prepareSideMenuCount(vals, allStatesValues[keys]);
              });
            }
          }
          this.getUserLastTranction(this.userProfileData);
        }
      }, (error: HttpErrorResponse) => {
        this.userInfo = null;
        this.console.log('error', error);
        this.toastService.presentToast
          (this.constVar.errorMsg, this.constVar.errorcolor);
      }),
    );
  }

  private async openSharePopup() {
    const shareRet = await Share.share({
      title: 'Share Better Today',
      text: 'Check out Better Today day planner and organizer. Plan your days using goal trackers, journals, todos and habit trackers',
      url: 'https://bettertoday.app',
      dialogTitle: 'Share Better Today'
    });
    this.analyticsService.logEvent(this.constVar.analytics.share);
  }

  private setSideMenuSlected(type: string): void {
    switch (type) {
      case 'dashboard':
        this.selectedIndex = 0;
        break;

      case 'todos':
        this.selectedIndex = 1;
        break;

      case 'goals':
        this.selectedIndex = 2;
        break;

      case 'memories':
        this.selectedIndex = 3;
        break;

      case 'gratitude':
        this.selectedIndex = 4;
        break;

      case 'healthstuff':
        this.selectedIndex = 5;
        break;

      case 'bookmark':
        this.selectedIndex = 6;
        break;
    }
  }

  private subscribeData(): void {
    this.globalService.UserProfileData
      .subscribe((res: any) => {
        if (res) {
          this.userType = res === this.constVar.userType.premiumUsers ?
            'Premium User' : 'Upgrade to Pro';
          this.changeDetectorRef.detectChanges();
        }
      }),
      this.globalService.userLoginStatusData
        .subscribe((responseData: any) => {
          if (responseData === 'logout') {
            this.userInfo = null;
            this.ionViewDidLeave();
          } else if (responseData === 'login') {
            this.getUserData();
          }
        }),

      this.subscriptions.push(
        this.router.events.pipe(
          filter((event: any) => event instanceof NavigationEnd)
        ).subscribe(event => {
          const type = event.url.split('/').slice(-1)[0];
          this.setSideMenuSlected(type);
        }),
      );

  }

  public trackById(index: number, page: any): string { return page.id; }

  private setBrowserTitle(): void {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(event => {
      const url = event.url.split('/');
      switch (url[(url.length - 1)]) {
        case 'todos': {
          this.titleService.setTitle('Todos');
          break;
        }
        case 'goals': {
          this.titleService.setTitle('Goals');
          break;
        }
        case 'memories': {
          this.titleService.setTitle('Memories');
          break;
        }
        case 'gratitude': {
          this.titleService.setTitle('Gratitude');
          break;
        }
        case 'healthstuff': {
          this.titleService.setTitle('Tracking');
          break;
        }
        case 'bookmark': {
          this.titleService.setTitle('Bookmark');
          break;
        }
        case 'dashboard': {
          this.titleService.setTitle('Better Today');
          break;
        }
        case 'upgrade-profile': {
          this.titleService.setTitle('Upgrade Profile');
          break;
        }
        default: {
          this.titleService.setTitle('Better Today');
          break;
        }
      }
    });
  }

  public async openVersionInfoModel() {
    const model = await this.modalController.create({
      component: VersionInfoComponent,
      mode: 'ios',
      backdropDismiss: false,
    });
    return await model.present();
  }

  private showNotification(): void {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const time1 = new Date(year, month, day, 8, 0, 0, 0);

    const notifs = LocalNotifications.schedule({
      notifications: [{
        title: 'BetterToday reminder',
        body: 'Have you checked your goals, todos, habits and journals today?',
        id: 1,
        schedule: { at: new Date(time1), every: 'day' },
        sound: '/assets/audio/beep.wav',
        attachments: null,
        actionTypeId: '',
        extra: null,
        autoCancel: true,
      }]
    });
  }


  getUserLastTranction(user) {
    this.subscriptions.push(
      this.userLoginService.getUserTranctions(user).subscribe(res => {
        // this.console.log('res last tranction----', res);
        // this.console.log(' this.userProfileData.userType----', this.userProfileData);
        if ( res.length && res[0].totalAmount === 0) {
          this.oldTranctions = true;
          this.userType = this.userProfileData.userType === this.constVar.userType.freeUser || this.oldTranctions === true ?  'Upgrade to Pro' : 'Premium User';
        }
      })
    );
  }

  // createInApp(){
  //   this.platform.ready().then(() => {
  //     // Only for debugging!
  //     this.store.verbosity = this.store.DEBUG;
  //     this.registerProducts();
  //     this.setupListeners();
  //     // Get the real product information
  //     this.console.log('this.store----------------------', this.store);
  //     this.store.ready(() => {
  //       this.products = this.store.products;
  //       this.console.log('products------------------------', this.products);
  //       this.globalService.inApp(this.store.products);
  //     });
  //   });
  // }

  // private registerProducts() {
  //   this.store.register({
  //     id: this.constVar.PRODUCT_MONTHLY_KEY,
  //     type: this.store.PAID_SUBSCRIPTION,
  //   });

  //   this.store.register({
  //     id: this.constVar.PRODUCT_ANNUAL_KEY,
  //     type: this.store.PAID_SUBSCRIPTION,
  //   });

  //   // Show errors for 10 seconds.
  //   this.store.error(function (error) {
  //     this.console.log('ERROR in store', error);
  //   });
  //   this.store.refresh();
  // }

  // private setupListeners() {
  //   // General query to all products
  //   this.store.when('product')
  //     .approved((p: IAPProduct) => {
  //       // Handle the product deliverable
  //       if (p.id === this.constVar.PRODUCT_ANNUAL_KEY) {
  //         this.isPro = true;
  //       } else if (p.id === this.constVar.PRODUCT_MONTHLY_KEY) {
  //         this.gems += 100;
  //       }
  //       // this.ref.detectChanges();
  //       return p.verify();
  //     })
  //     .verified((p: IAPProduct) => p.finish());
  //   // Specific query for one ID
  //   this.store.when(this.constVar.PRODUCT_ANNUAL_KEY).owned((p: IAPProduct) => {
  //     this.isPro = true;
  //   });
  // }

  // public purchase(product: IAPProduct) {
  //   this.store.order(product).then((p: any) => {
  //     this.console.log('p----------------------', p);
  //     // Purchase in progress!
  //   }, (e: any) => {
  //     // this.presentAlert('Failed', `Failed to purchase: ${e}`);
  //   });
  // }

  // // To comply with AppStore rules
  // public restore() {
  //   this.store.refresh();
  // }

  // private async presentAlert(header: any, message: any) {
  //   const alert = await this.alertController.create({
  //     header,
  //     message,
  //     buttons: ['OK']
  //   });
  //   await alert.present();
  // }

}

