import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { VersionService } from '../api-service';
import { Plugins } from '@capacitor/core';
import { ConsoleService } from '../services/console.service';
const { Browser } = Plugins;
@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss'],
})
export class VersionInfoComponent implements OnInit {

  public versionListData: any;

  private subscriptions: Subscription[] = [];

  constructor(
    private modalController: ModalController,
    private versionService: VersionService,
    private console: ConsoleService,
  ) { }

  public ngOnInit(): void {
    this.getVersionList();
  }

  public ionViewDidLeave(): void {
    this.subscriptions.forEach((sub) => {
      if (sub && !sub.closed) {
        sub.unsubscribe();
      }
    });
  }

  public closeModal(): void {
    this.modalController.dismiss();
  }

  private getVersionList(): void {
    this.subscriptions.push(
      this.versionService.getVersionList()
        .subscribe((responseData: any) => {
          if (responseData && responseData.length) {
            this.versionListData = responseData;
          }
        }, (error: HttpErrorResponse) => {
          this.console.log('error', error);
        })
    );
  }

  public async clickOnReadMore(item: any): Promise<any> {
    await Browser.open({ url: item.url });
  }
}
