import { Injectable } from '@angular/core';
import { ConstantVariables } from 'src/const/constant';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(
    private angularFirestore: AngularFirestore,
    private constVar: ConstantVariables,
  ) { }

  public getVersionList(): Observable<any> {
    const perPageLimit = 5;
    return this.angularFirestore.collection('versionInfo', ref => ref
      .where('platform', '==', 'mobile')
      .orderBy('docId', 'desc').limit(perPageLimit))
      .snapshotChanges().pipe(
        map((actions: any) => {
          return actions.map((a: any) => {
            const data = a.payload.doc.data();
            return { ...data };
          });
        })
      );
  }
}
