import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, ToastController, AlertController, Platform, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ToastService, AnalyticsService } from 'src/app/services';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ConstantVariables } from 'src/const/constant';
import { DatePipe } from '@angular/common';
import { AngularFireFunctions } from '@angular/fire/functions';
declare var stripe: any;
import { Capacitor } from '@capacitor/core';
import { IAPProduct, InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { UserLoginService } from 'src/app/api-service/user-login/user-login.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { InAppService } from 'src/app/api-service/in-app.service';
import { ActionModalComponent } from '../action-modal/action-modal.component';
import { ConsoleService } from 'src/app/services/console.service';
@Component({
  selector: 'app-upgrade-model',
  templateUrl: './upgrade-model.component.html',
  styleUrls: ['./upgrade-model.component.scss'],
})
export class UpgradeModelComponent {

  @Input() ionIcon: any;
  @Input() titleIcon: any;
  @Input() headerTitle: any;
  @Input() headerSubTitle: any;

  public payAmount: any;
  public msg: any;
  // public isIos = true;
  public selectAmout = 1;
  public configData: any;
  public applyStatus = true;
  public succsMsg: any;
  public errorMsg: any;
  public yearlyAmount = 0;
  public monthlyAmount = 0;
  public loading = false;
  public showMobileMsg: boolean;
  public savePercent = 0;
  public copenCode: any;
  public applyBtnStatus = false;
  public userData: any;
  public userUpgradeForm: FormGroup;
  public slideOpts = {
    speed: 400,
    initialSlide: 1,
    slidesPerView: 1,
    autoplay: {
      disableOnInteraction: false
    }
  };
  public slideImages = [];

  private realCopenCode: any;
  private totalAmount: any = 0;
  private subscriptionType: string;
  private discountAmount = 0;
  private copenDocId: any;
  private subscriptions: Subscription[] = [];

  public checkPlateform: any;
  public gems = 0;
  public isPro = false;
  // public products: IAPProduct[] = [];
  public products = [];

  selectedProduct: any;
  inAppAnulaDis: any = 0;
  clikToUpgrade = false;
  alradyOwend = false;

  constructor(
    public datePipe: DatePipe,
    private angularFireFunctions: AngularFireFunctions,
    private alertController: AlertController,
    private constVar: ConstantVariables,
    private modalController: ModalController,
    private toastService: ToastService,
    private userLoginService: UserLoginService,
    private toastController: ToastController,
    private ref: ChangeDetectorRef,
    private httpClient: HttpClient,
    // private platform: Platform,
    // private store: InAppPurchase2,
    private analyticsService: AnalyticsService,
    private globalService: GlobalService,
    private inAppService: InAppService,

    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private console: ConsoleService,


  ) {

    // this.platform.ready().then(() => {
    //   // Only for debugging!
    //   // this.store.verbosity = this.store.DEBUG;
    //   // this.registerProducts();
    //   // this.setupListeners();
    //   // // Get the real product information
    //   // this.console.log('this.store----------------------', this.store);
    //   this.store.ready(() => {
    //     this.products = this.store.products;
    //     this.registerHandlersForPurchase();
    //     // this.console.log('products------------------------', this.products);
    //     // this.ref.detectChanges();
    //   });
    // });

    // this.inAppService.upgradeUser('');
    // this.paymentDoneAlert()
  }

  public ionViewDidEnter(): void {

    if (this.userLoginService.infoCurrentUser) {
      this.console.log('user exist', this.userLoginService.infoCurrentUser);
      this.userData = this.userLoginService.infoCurrentUser.data;
    } else {
      this.userLoginService.getUserData().subscribe((userData: any) => {
      });
    }

    this.checkPlateform = Capacitor.isNative;
    if (this.checkPlateform) {
      this.globalService.inAppSourceData.subscribe(res => {
        this.console.log('this.products in upgrade-----------------', res);
        this.products = res;


        if (this.products[0].owned === true){
          this.selectedProduct = this.products[0];
          this.alradyOwend = true;
          this.selectAmout = 2;
        } else if (this.products[1].owned === true){
          this.selectedProduct = this.products[1];
          this.alradyOwend = true;
          this.selectAmout = 1;
        } else {
          this.selectedProduct = res[1];
          this.alradyOwend = false;
        }
        const dis = (this.products[0].priceMicros * 12);
        const disPrice = dis - this.products[1].priceMicros;
        this.inAppAnulaDis = ((disPrice * 100) / dis).toFixed(0);
      });
    }

    // this.isIos = this.platformService.isIos();
    this.getSliderData();
    this.getConfig();
    this.getUserData();

    // this.checkPlateform = true;
    // this.products = [
    //   {
    //     "id": "promonthly",
    //     "alias": "promonthly",
    //     "type": "paid subscription",
    //     "group": "default",
    //     "state": "valid",
    //     "title": "Monthly Subscription",
    //     "description": "Unlock all the pro benefits. Billed monthly. Cancel anytime.",
    //     "priceMicros": 210000000,
    //     "price": "₹210.00",
    //     "currency": "INR",
    //     "countryCode": null,
    //     "loaded": true,
    //     "canPurchase": true,
    //     "owned": true,
    //     "introPrice": "",
    //     "introPriceMicros": "",
    //     "introPricePeriod": null,
    //     "introPriceNumberOfPeriods": null,
    //     "introPricePeriodUnit": null,
    //     "introPriceSubscriptionPeriod": null,
    //     "introPricePaymentMode": null,
    //     "ineligibleForIntroPrice": null,
    //     "discounts": [],
    //     "downloading": false,
    //     "downloaded": false,
    //     "additionalData": null,
    //     "transaction": null,
    //     "trialPeriod": null,
    //     "trialPeriodUnit": null,
    //     "billingPeriod": 1,
    //     "billingPeriodUnit": "Month",
    //     "valid": true
    //   },
    //   {
    //     "id": "proannual",
    //     "alias": "proannual",
    //     "type": "paid subscription",
    //     "group": "default",
    //     "state": "valid",
    //     "title": "Annual subscription",
    //     "description": "Unlock all pro features. Billed annually. Cancel anytime.",
    //     "priceMicros": 2150000000,
    //     "price": "₹2,150.00",
    //     "currency": "INR",
    //     "countryCode": null,
    //     "loaded": true,
    //     "canPurchase": true,
    //     "owned": false,
    //     "introPrice": "",
    //     "introPriceMicros": "",
    //     "introPricePeriod": null,
    //     "introPriceNumberOfPeriods": null,
    //     "introPricePeriodUnit": null,
    //     "introPriceSubscriptionPeriod": null,
    //     "introPricePaymentMode": null,
    //     "ineligibleForIntroPrice": null,
    //     "discounts": [],
    //     "downloading": false,
    //     "downloaded": false,
    //     "additionalData": null,
    //     "transaction": null,
    //     "trialPeriod": null,
    //     "trialPeriodUnit": null,
    //     "billingPeriod": 1,
    //     "billingPeriodUnit": "Year",
    //     "valid": true
    //   }
    // ];
    // const dis = (this.products[0].priceMicros * 12);
    // const disPrice = dis - this.products[1].priceMicros;
    // this.inAppAnulaDis = ((disPrice * 100) / dis).toFixed(0);
    // if (this.products[0].owned) {
    //   this.selectedProduct = this.products[0];
    //   this.alradyOwend = true;
    // } else if (this.products[1].owned) {
    //   this.selectedProduct = this.products[1];
    //   this.alradyOwend = true;
    // } else {
    //   this.selectedProduct = this.products[1];
    //   this.alradyOwend = false;
    // }
  }

  public ionViewDidLeave(): void {
    // this.products = [];
    this.subscriptions.forEach((sub) => {
      if (sub && !sub.closed) {
        sub.unsubscribe();
      }
    });
  }

  private getConfig(): void {
    this.subscriptions.push(
      this.userLoginService.getConfigData().subscribe(res => {
        this.console.log('iam getConfigData subscribe', res);
        this.configData = res[0];

        // this.payAmount = this.configData.annualCharge;
        // this.totalAmount = this.configData.annualCharge;

        this.payAmount = this.checkPlateform ? this.selectedProduct.priceMicros / 1000000 : this.configData.annualCharge;
        this.totalAmount = this.checkPlateform ? this.selectedProduct.priceMicros / 1000000 : this.configData.annualCharge;

        this.subscriptionType = this.constVar.ANNUAL_SUBSCRIPTION;
        this.yearlyAmount = this.configData.monthlyCharge * 12;
        this.monthlyAmount = this.configData.monthlyCharge;
        const monthWiseYearly = this.configData.monthlyCharge * 12;
        this.savePercent = ((monthWiseYearly - this.configData.annualCharge) / (monthWiseYearly / 100));
        this.savePercent = Math.round(this.savePercent);
        this.yearlyAmount = this.payAmount;
        this.copenCode = this.configData.coupon ? this.configData.coupon : '';
        if (this.copenCode) {
          this.checkCoupon();
        }
      }, (error: HttpErrorResponse) => {
        this.console.log('error', error);
        this.toastService.presentToast
          (this.constVar.errorMsg, this.constVar.errorcolor);
      })
    );
  }

  public selectPayAmount(value: any) {
    this.selectAmout = value;
    this.succsMsg = null;
    this.copenCode = '';
    if (value === 1) {
      this.payAmount = this.configData.annualCharge;
      this.totalAmount = this.configData.annualCharge;
      this.subscriptionType = this.constVar.ANNUAL_SUBSCRIPTION;
    } else {
      this.payAmount = this.configData.monthlyCharge;
      this.totalAmount = this.configData.monthlyCharge;
      this.subscriptionType = this.constVar.MONTHLY_SUBSCRIPTION;
      // this.checkPlateform ? this.selectedProduct.price : this.totalAmount;
    }

  }

  public trackById(slide: any): string { return slide.id; }

  public dismissModel(): void {
    this.analyticsService.logEvent(this.constVar.analytics.upgradeDismissed);
    this.modalController.dismiss('close');
  }

  public checkCoupon(): void {
    this.subscriptions.push(
      this.userLoginService.getCouponData(this.copenCode.toLowerCase()).subscribe(res => {
        this.console.log('iam getCouponData subscribe', res);
        // if (res && res.length) {
        //   const validDate = this.datePipe.transform(res[0].couponValidTill.seconds * 1000, 'd MMM, y, h:mm a');
        //   const currentDate = new Date();
        //   if (new Date(validDate) < new Date(currentDate)) {
        //     this.errorMsg = 'Coupon has expired.';
        //   } else {
        //     this.applyStatus = true;
        //     this.payAmount = this.totalAmount;
        //     this.errorMsg = 'Invalid coupon code';
        //     this.succsMsg = null;
        //   }
        // }
        if (res && res.length) {
          const validDate = this.datePipe.transform(res[0].couponValidTill.seconds * 1000, 'd MMM, y, h:mm a');
          const currentDate = new Date();
          if (new Date(validDate) < new Date(currentDate)) {
            this.errorMsg = 'Coupon has expired.';
            this.realCopenCode = '';
            this.copenDocId = '';
          } else {
            this.errorMsg = null;
            this.applyStatus = true;
            this.discountAmount = (this.totalAmount * res[0].discountPercent) / 100;
            this.payAmount = this.totalAmount - this.discountAmount;
            this.realCopenCode = res[0].coupon;
            this.copenDocId = res[0].docId;
            this.applyBtnStatus = true;
            this.succsMsg = `Coupon applied successfully`;
          }
        } else {
          this.applyStatus = true;
          this.payAmount = this.totalAmount;
          this.errorMsg = 'Invalid coupon code';
          this.succsMsg = null;
          this.realCopenCode = '';
          this.copenDocId = '';
        }
      }, (error: HttpErrorResponse) => {
        this.console.log('error', error);
        this.toastService.presentToast
          (this.constVar.errorMsg, this.constVar.errorcolor);
      })
    );
  }

  public checkCoupanText(): void {

    if (this.checkPlateform) {
      this.payAmount = this.selectedProduct.price;
    }

    this.succsMsg = null;
    this.discountAmount = 0;
    this.applyStatus = false;
    if (this.copenCode !== '') {
      this.applyBtnStatus = true;
    } else {
      this.errorMsg = null;
      this.applyBtnStatus = false;
      this.applyStatus = true;

      this.realCopenCode = '';
      this.copenDocId = '';
    }

  }

  public upgradeUser(inApp?): void {
    this.console.log(`1000 About to upgradeUser with inApp=`, inApp);
    const upgradeClick = [{ action: 'upgradeClick', user: this.userData.email }];
    this.analyticsService.logEvent(this.constVar.analytics.upgradeClick, upgradeClick);
    this.loading = true;
    this.applyStatus = false;

    const transactionData: any = {
      userId: this.userData.docId,
      userEmail: this.userData.email,
      firstName: this.userData.firstName,
      lastName: this.userData.lastName,
      totalAmount: this.totalAmount,
      discountCode: this.realCopenCode ? this.realCopenCode : '',
      copenDocId: this.copenDocId ? this.copenDocId : '',
      configDocId: inApp ? '' : this.configData.docId,
      discountAmount: this.discountAmount,
      netPayableAmount: (this.totalAmount - this.discountAmount),
      txnDuration: this.subscriptionType,
      hostUrl: window.location.origin,
    };

    if (inApp === true) {
      transactionData.byInApp = true;
      transactionData.byInAppAmount = (this.totalAmount - this.discountAmount);
      transactionData.inAppMonthlyCharge = Number(this.products[0].priceMicros / 1000000);
      transactionData.inAppannualCharge = Number(this.products[1].priceMicros / 1000000);
    }

    this.console.log('1100 transactionData', transactionData);

    this.subscriptions.push(
      this.userLoginService.addTranction(transactionData)
        .subscribe(async (res: any) => {
          this.console.log('i am addTranction subscribe', res);
          const completeTransaction = [{
            value: transactionData.netPayableAmount,
            coupon: transactionData.discountCode,
            totalPayableAmount: transactionData.netPayableAmount === 0 ? 'free' : transactionData.netPayableAmount,
          }];
          this.analyticsService.logEvent(this.constVar.analytics.upgradeCompleted, completeTransaction);
          if (res.status === 200) {
            if (res.message === 'Payment process is successfuly done') {
              this.presentToast(res.message, `success`);
              this.modalController.dismiss(res);
              this.loading = false;
              this.applyStatus = true;
            } else {
              await stripe.redirectToCheckout({
                sessionId: res.message.checkoutSessionId,
              });
            }
          } else {
            this.presentToast(res.message, 'danger');
            this.loading = false;
            this.applyStatus = true;
          }
          this.ref.detectChanges();
        }, (error: HttpErrorResponse) => {
          this.console.log('error', error);
          this.toastService.presentToast
            (this.constVar.errorMsg, this.constVar.errorcolor);
        })
    );
    // }
  }

  private async presentToast(msg = 'Done', toastColor = 'dark') {
    const toast = await this.toastController.create({
      message: `${msg}`,
      color: toastColor,
      duration: 3000,
      cssClass: 'toastGlobal'
    });
    toast.present();
  }

  private getUserData(): void {
    // this.subscriptions.push(
    //   this.userLoginService.getUserData().subscribe((responseData: any) => {
    //     // tslint:disable-next-line: no-unused-expression
    //     !environment.production && this.console.log('iam getUserData subscribe', responseData);
    //     if (responseData.length) {
    //       this.userData = responseData[0].data;
    //     }
    //   }, (error: HttpErrorResponse) => {
    //     this.console.log('error', error);
    //     this.toastService.presentToast
    //       (this.constVar.errorMsg, this.constVar.errorcolor);
    //   }),
    // );

    if (this.userLoginService.infoCurrentUser) {
      this.userData = this.userLoginService.infoCurrentUser.data;
    }
  }

  // TODO: this method can use to get publishable key from backend
  private getPublishableKey() {
    const callable = this.angularFireFunctions.httpsCallable('stripeSessions');
    const obs = callable({ type: this.constVar.GET_PUBLISH_KEY });
    obs.subscribe(resp => {
    }, err => {
      this.console.log(err);
    });
  }

  private async showMobilePaymentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: `${this.constVar.loginDetailMsg}`,
      backdropDismiss: false,
      mode: 'ios',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            // this.console.log('Confirm Okay');
          }
        }
      ]
    });
    await alert.present();
  }

  private getSliderData(): void {
    this.subscriptions.push(
      this.httpClient.get('assets/mock-data/upgrade-slider.json')
        .subscribe((sliderData: any) => {
          this.slideImages = sliderData;
        }, (error: HttpErrorResponse) => {
          this.console.log('error', error);
        }),
    );
  }

  public purchase(product: IAPProduct, value) {
    if (this.alradyOwend === true) {
      return;
    } else {
      this.selectedProduct = product;
      this.selectAmout = value;
      this.succsMsg = null;
      this.copenCode = '';
      if (value === 1) {
        this.payAmount = product.priceMicros / 1000000;
        this.totalAmount = product.priceMicros / 1000000;
        this.subscriptionType = this.constVar.ANNUAL_SUBSCRIPTION;
      } else {
        this.payAmount = product.priceMicros / 1000000;
        this.totalAmount = product.priceMicros / 1000000;
        this.subscriptionType = this.constVar.MONTHLY_SUBSCRIPTION;
      }
    }
  }

  // To comply with AppStore rules
  public restore() {
    this.inAppService.restore();
  }

  upgradeUserInApp() {
    this.clikToUpgrade = true;
    this.console.log('100 About to upgrade user in upgradeUserInApp');
    this.console.log(`100 and $totalAmount=${this.totalAmount}  discountAmount=${this.discountAmount}`);
    if ((this.totalAmount - this.discountAmount) === 0 && this.copenCode !== '') {
      this.console.log(`300 Came inside this.copenCode`);
      this.upgradeUser(true);
    } else {
      this.console.log(`400 Came inside else, about to call insappService.purchase`, this.selectedProduct);
      if (this.selectedProduct.owned === true) {
        // this.inAppService.upgradeUser(this.selectedProduct);
      } else {
        this.inAppService.purchase(this.selectedProduct).subscribe(res => {
          this.console.log(`500 Result is `, res);
          if (res.status === 200) {
            this.console.log(`600 Came inside res.status==200 because res.status is ${res.status}`);
            // this.upgradeUser(true);
            this.successPopover(null);
            this.modalController.dismiss(res);
          } else {
            this.presentAlert('Failed', `Failed to purchase:${res.data}`);
          }
        });
      }
    }
  }

  private async presentAlert(header: any, message: any) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  // <i class="fad fa-spinner-third" > </i>
  async successPopover(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: ActionModalComponent,
      event: ev,
      translucent: true,
      animated: true,
      showBackdrop: true,
      cssClass: 'popover_class',
      mode: 'ios',
      componentProps: {
        title: 'Success',
        message: 'Please follow the instructions of app store.',
        img: 'fas fa-spinner',
        buttonType: 'close',
        crown: false,
      }
    });
    popover.onDidDismiss().then(() => {
      this.modalCtrl.dismiss('success');
    });
    return await popover.present();
  }

  // private registerProducts() {
  //   this.store.register({
  //     id: this.constVar.PRODUCT_MONTHLY_KEY,
  //     type: this.store.PAID_SUBSCRIPTION,
  //   });

  //   this.store.register({
  //     id: this.constVar.PRODUCT_ANNUAL_KEY,
  //     type: this.store.PAID_SUBSCRIPTION,
  //   });

  //   // Show errors for 10 seconds.
  //   this.store.error(function(error) {
  //     this.console.log('ERROR in store', error);
  //   });
  //   this.store.refresh();
  // }

  // private setupListeners() {
  //   // General query to all products
  //   this.store.when('product')
  //     .approved((p: IAPProduct) => {
  //       // Handle the product deliverable
  //       if (p.id === this.constVar.PRODUCT_ANNUAL_KEY) {
  //         this.isPro = true;
  //       } else if (p.id === this.constVar.PRODUCT_MONTHLY_KEY) {
  //         this.gems += 100;
  //       }
  //       this.ref.detectChanges();
  //       return p.verify();
  //     })
  //     .verified((p: IAPProduct) => p.finish());
  //   // Specific query for one ID
  //   this.store.when(this.constVar.PRODUCT_ANNUAL_KEY).owned((p: IAPProduct) => {
  //     this.isPro = true;
  //   });
  // }

  // public purchase(product: IAPProduct) {
  //   this.store.order(product).then((p: any) => {
  //     this.console.log('p----------------------', p);
  //     // Purchase in progress!
  //   }, (e: any) => {
  //     this.presentAlert('Failed', `Failed to purchase: ${e}`);
  //   });
  // }

  // // To comply with AppStore rules
  // public restore() {
  //   this.store.refresh();
  // }

  // private async presentAlert(header: any, message: any) {
  //   const alert = await this.alertController.create({
  //     header,
  //     message,
  //     buttons: ['OK']
  //   });
  //   await alert.present();
  // }


}
