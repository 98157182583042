<ion-content scroll="false">
  <div class="modal-content">
    <ion-grid>
      <ion-row class="ion-text-center">
        <ion-col size="12" class="ion-no-padding" *ngIf ="crown">
          <i class="{{img}} crown"></i>
        </ion-col>
        <ion-col size="12" class="ion-no-padding" *ngIf="!crown">
          <i class="{{img}} loding"></i>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" class="ion-text-center">
          <p class="msg">
          {{message}}
          </p>
        </ion-col>
      </ion-row>
      <ion-row >
        <ion-col class="ion-text-center dismiss_btn">
          <ion-button class="close" fill="clear" (click)="dismissPopover()">
            <b>DISMISS</b>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>