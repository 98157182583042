export const environment = {
  production: true,
  emulators: false,
  firebase: {
    apiKey: 'AIzaSyBZ5_tYEoxazcXGdlVKAtwxGjUf2tvmJJ0',
    authDomain: 'better-today-live.firebaseapp.com',
    databaseURL: 'https://better-today-live.firebaseio.com',
    projectId: 'better-today-live',
    storageBucket: 'better-today-live.appspot.com',
    messagingSenderId: '339792141634',
    appId: '1:339792141634:web:bc6171783417a95211fcdb',
    measurementId: 'G-MS2HL1CCPH'
  }
};
