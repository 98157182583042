import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { DateService } from 'src/app/services';
import { ConsoleService } from 'src/app/services/console.service';
import { ConstantVariables } from 'src/const/constant';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class HealthStuffService {

  /* public clearEntry = new BehaviorSubject<boolean>(false);
  public clearEntryData = this.clearEntry.asObservable(); */

  constructor(
    private angularFirestore: AngularFirestore,
    private dateService: DateService,
    private constVar: ConstantVariables,
    private console: ConsoleService,
    private angularFireFunctions: AngularFireFunctions,
  ) { }

  public addUserStuffProperties(docId: string) {
    this.angularFirestore.collection('users').doc(docId)
      .collection('healthStuffProperties')
      .get().toPromise().then(res => {
        if (res.empty) {
          this.angularFirestore.collection('defaultProperties').get().toPromise().then(
            (actions: any) => {
              actions.docs.map((doc: any) => {
                this.angularFirestore.collection('users').doc(docId)
                  .collection('healthStuffProperties').add(doc.data());
              });
            }
          );
        }
      });
  }

  public addNewStuffData(docId: string, dataObj: any): Observable<any> {
    const obj = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(docId)
        .collection('healthStuffProperties').add(dataObj).then(async (response) => {
          observer.next({
            status: 200,
            message: this.constVar.addedStuff,
            data: response
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : this.constVar.someIssue,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obj;
  }

  public editHealthStuffData(uid: string, docId: string, editData: any): Observable<any> {
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(uid).collection('healthStuffProperties')
        .doc(docId).update(editData).then(async () => {
          observer.next({
            message: this.constVar.healthStuffDataUpdate,
            status: 200,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : this.constVar.someIssue,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public changeDailyStatus(data: any, docId: string, userId: string): Observable<any> {
    const obs = new Observable((observer) => {
      if (docId) {
        this.angularFirestore.collection('users').doc(userId)
          .collection('dailyHealthStatus').doc(docId).update(data).then(async () => {
            observer.next({
              message: 'DailyHealthStatus updated successfuly.',
              status: 200,
            });
          }).catch((error) => {
            observer.next({
              message: error.message ? error.message : 'There is some issue...',
              status: error.code ? error.code : 400,
            });
          });
      } else {
        this.angularFirestore.collection('users').doc(userId)
          .collection('dailyHealthStatus').add(data).then(async (response) => {
            observer.next({
              status: 200,
              message: 'Added dailyStatus successfully',
              data: response
            });
          }).catch((error) => {
            observer.next({
              message: error.message ? error.message : 'There is some issue...',
              status: error.code ? error.code : 400,
            });
          });
      }
    });
    return obs;
  }

  public getlastValue(userId: string, currentDate: Date, stuffData: any): Observable<any> {
    const prepareDailyData = [...stuffData];
    stuffData.map((res: any, index: number) => {
      this.getPreviousHealthStatusforToday(
        userId,
        currentDate,
        res.id
      ).subscribe((item) => {
        if (item.length) {
          const data = item[0].data;
          prepareDailyData[index].lastValue = (data && data.value) ? data.value : '';
        }
      });
    });
    return of(prepareDailyData);
  }

  public getDailyHealthStatus(userId: string, date: Date, stuffData: any): Observable<any> {
    return this.angularFirestore.collection('users').doc(userId)
      .collection('dailyHealthStatus', ref => ref.where('date', '==', date))
      .stateChanges().pipe(
        map((payloads: any) => {
          return payloads.map((doc: any) => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            const type = doc.payload.type;
            return { ...data, docId, type };
          });
        }),
        mergeMap((data: any) => {
          if (!data.length && stuffData.length) {
            return this.getlastValue(userId, date, stuffData);
          } else {
            const result = stuffData.filter((o1: any) => data.some((o2: any) => o1.id !== o2.itemid));
            return of(data, this.getlastValue2(userId, date, result));
          }
        }),
        catchError(err => of({
          message: err.message ? err.message : 'There is some issue...',
          status: err.code ? err.code : 400,
        })),
      );
  }

  public getlastValue2(userId: string, currentDate: Date, stuffData: any) {
    const prepareDailyData = [...stuffData];
    stuffData.map((res: any, index: number) => {
      this.getPreviousHealthStatusforToday(
        userId,
        currentDate,
        res.id
      ).subscribe((item) => {
        if (item.length) {
          const data = item[0].data;
          prepareDailyData[index].lastValue = (data && data.value) ? data.value : '';
        }
      });
    });
    return prepareDailyData;
  }

  public getHealthStuffData(docId: string): Observable<any> {
    return this.angularFirestore.collection('users').doc(docId)
      .collection('healthStuffProperties', ref => ref.orderBy('position', 'asc'))
      .snapshotChanges().pipe(
        map((payloads: any) => {
          let colorIndex = 0;
          return payloads.map((doc: any) => {
            if (colorIndex > 5) { colorIndex = 0; }
            const data = doc.payload.doc.data();
            data.color = this.constVar.trackerWidgetColors[colorIndex];
            const id = doc.payload.doc.id;
            colorIndex++;
            return { ...data, id };
          });
        }),
        catchError(err => of({
          message: err.message ? err.message : 'There is some issue...',
          status: err.code ? err.code : 400,
        })),
      );
  }

  public getPreviousHealthStatusforToday(
    userId: string,
    currentDate: Date,
    itemid: any
  ): Observable<any> {
    return this.angularFirestore.collection('users').doc(userId)
      .collection('dailyHealthStatus', ref =>
        ref.where('itemid', '==', itemid)
          .where('date', '<', currentDate).orderBy('date', 'desc').limit(1)
      ).stateChanges().pipe(
        map((payloads: any) => {
          return payloads.map((doc: any) => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { data, docId };
          });
        }),
        catchError(err => of({
          message: err.message ? err.message : 'There is some issue...',
          status: err.code ? err.code : 400,
        })),
      );
  }

  public async getPreviousDateData(
    userId: string, currentDate: Date, itemId: string
  ): Promise<any> {
    try {
      return await this.angularFirestore.collection('users').doc(userId)
        .collection('dailyHealthStatus', ref => ref.where('userid', '==', userId)
          .where('itemid', '==', itemId)
          .where('date', '<', currentDate).orderBy('date', 'desc')).get().toPromise().then(res => {
            return res.docs.map(data => {
              const id = data.id;
              const info = data.data();
              return { docId: id, ...info };
            });
          });
    } catch (e) {
      return await e;
    }
  }

  public async getPreViousDayData3(
    userid: string, currentDate: Date, itemid: string
  ): Promise<any> {
    try {
      return await this.angularFirestore.collection('users').doc(userid)
        .collection('dailyHealthStatus', ref => ref.where('userid', '==', userid)
          .where('itemid', '==', itemid)
          .where('date', '<', currentDate).orderBy('date', 'desc').limit(1)).get().toPromise().then(res => {
            return res.docs.map(data => {
              const id = data.id;
              const info = data.data();
              return { docId: id, ...info };
            });
          });
    } catch (e) {
      return await e;
    }
  }

  public async previousDaysCalculateAverageDataBeforeDate(
    userid: string,
    itemId: string,
    calenderDate: Date
  ): Promise<any> {
    try {
      return await this.angularFirestore.collection('users')
        .doc(userid).collection('dailyHealthStatus', ref => ref
          .where('userid', '==', userid)
          .where('itemid', '==', itemId)
          .where('date', '>', calenderDate).orderBy('date', 'asc'))
        .get().toPromise().then(res => {
          return res.docs.map(data => {
            const id = data.id;
            const info = data.data();
            return { docId: id, ...info };
          });
        });
    } catch (e) {
      return await e;
    }
  }

  public prepareChartData(
    userid: string,
    itemId: string,
    fromDate: Date,
    toDate: Date
  ): Observable<any> {
    return this.angularFirestore.collection('users').doc(userid)
      .collection('dailyHealthStatus', ref => ref.where('userid', '==', userid)
        .where('itemid', '==', itemId)
        .where('date', '>=', fromDate)
        .where('date', '<=', toDate))
      .snapshotChanges().pipe(
        map((payloads: any) => {
          return payloads.map((doc: any) => {
            const data = doc.payload.doc.data();
            return data;
          });
        }),
        catchError(err => of({
          message: err.message ? err.message : 'There is some issue...',
          status: err.code ? err.code : 400,
        })),
      );
  }

  public async getNextDateData(
    userid: string, currentDate: Date, itemid: string
  ): Promise<any> {
    try {
      return await this.angularFirestore.collection('users').doc(userid)
        .collection('dailyHealthStatus', ref => ref.where('userid', '==', userid)
          .where('itemid', '==', itemid)
          .where('date', '>', currentDate).orderBy('date', 'asc').limit(1)).get().toPromise().then(res => {
            return res.docs.map(data => {
              const id = data.id;
              const info = data.data();
              return { docId: id, ...info };
            });
          });
    } catch (e) {
      return await e;
    }
  }

  public clearTrackerValueForSelectedDate(docId: string, userId: string): Observable<any> {
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(userId)
        .collection('dailyHealthStatus').doc(docId)
        .delete().then(async () => {
          observer.next({
            message: this.constVar.valueReset,
            status: 200,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : this.constVar.someIssue,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public async getDailyHealthStatusForChartCheckbox(
    userid: string,
    itemId: string,
  ): Promise<any> {
    try {
      return await this.angularFirestore.collection('users')
        .doc(userid).collection('dailyHealthStatus', ref => ref
          .where('userid', '==', userid)
          .where('itemid', '==', itemId)
          .orderBy('date', 'desc'))
        .get().toPromise().then(res => {
          return res.docs.map(data => {
            const id = data.id;
            const info = data.data();
            return { docId: id, ...info };
          });
        });
    } catch (e) {
      return await e;
    }
  }

  public getHealthStuffDataByLimit(userid: string, itemid: any, limit: number): Observable<any> {
    return this.angularFirestore.collection('users').doc(userid)
      .collection('dailyHealthStatus', ref => ref
        .where('userid', '==', userid)
        .where('itemid', '==', itemid)
        .limit(limit)
      ).snapshotChanges().pipe(
        map((actions) => {
          return actions.map(doc => {
            const data = doc.payload.doc.data();
            const id = doc.payload.doc.id;
            return { docId: id, ...data };
          });
        })
      );
  }

  // TODO DELETE TRACKER
  public deleteHealthStuffData(uid: string, docId: string): Observable<any> {
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(uid).collection('healthStuffProperties')
        .doc(docId).delete().then(async () => {
          observer.next({
            message: this.constVar.healthStuffDataDelete,
            status: 200,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : this.constVar.someIssue,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public getAllPreviousHealthStatus(userid: string, itemid: any): Observable<any> {
    const obj = new Observable((observer) => {
      let previousDayData = [];
      this.angularFirestore.collection('users').doc(userid).collection('dailyHealthStatus', ref =>
        ref.where('userid', '==', userid).where('itemid', '==', itemid)
      ).snapshotChanges().pipe().forEach(actions => {
        previousDayData = [];
        actions.forEach((doc: any) => {
          const data: any = doc.payload.doc.data();
          const docId = doc.payload.doc.id;
          previousDayData.push({ docId, data });
        });
        observer.next({
          status: 200,
          message: 'Get previousDay data successfuly',
          data: previousDayData,
        });
      }).catch((error) => {
        this.console.log('error', error);
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obj;
  }

  public clearDailyHealthStatus(docId: string, userId: string): Observable<any> {
    const obs = new Observable((observer) => {
      this.angularFirestore.collection('users').doc(userId).collection('dailyHealthStatus')
        .doc(docId).delete().then(async () => {
          observer.next({
            message: this.constVar.valueReset,
            status: 200,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : this.constVar.someIssue,
            status: error.code ? error.code : 400,
          });
        });
    });
    return obs;
  }

  public getHealthStuffDataByLimitDate(userid: string, date: any, itemObj: any, limit: number): Observable<any> {
    const itemId = itemObj[0].id;
    return this.angularFirestore.collection('users').doc(userid)
      .collection('dailyHealthStatus', ref => ref
        .where('userid', '==', userid)
        .where('itemid', '==', itemId)
        .where('date', '==', date)
        .limit(limit)
      ).snapshotChanges().pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            const type = doc.payload.type;
            return { ...data, docId, type };
          });
        }),
        mergeMap((data: any) => {
          if (!data.length && itemObj.length) {
            return this.getlastValue(userid, date, itemObj);
          } else {
            return of(data, this.getlastValue2(userid, date, itemObj));
          }
        })
      );
  }

  public calculateNextDate(data: any) {
    const newObs = new Observable((observer) => {
      const callable = this.angularFireFunctions.httpsCallable('calculateDate');
      const obs = callable({ allData: data });
      obs.subscribe(resp => {
        observer.next({
          status: 200,
          res: resp,
        });
      }, err => {
        observer.next({
          status: 500,
          message: 'error',
        });
      });
    });
    return newObs;

  }
}
