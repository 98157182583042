import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConstantVariables } from '../../../const/constant';

@Injectable({
  providedIn: 'root'
})

export class BookmarkService {

  constructor(
    private db: AngularFirestore,
    private constVar: ConstantVariables,
  ) {}

  public addBookmark(userDocId: string, bookmarkData: any): Observable<any> {
    const data = {
      name: bookmarkData.name,
      url: bookmarkData.url,
      userId: userDocId,
      icon: bookmarkData.icon,
      date: bookmarkData.date
    };
    const obs = new Observable((observer) => {
      this.db.collection('users').doc(userDocId).collection('bookmark').add(data).then(async (response) => {
        observer.next({
          message: this.constVar.bookmarkAdd,
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : this.constVar.someIssue,
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public getBookmarkData(user: any): Observable<any> {
    return this.db.collection('users').doc(user.uid)
      .collection('bookmark', ref => ref.orderBy('date', 'asc'))
      .snapshotChanges().pipe(
        map((actions) => {
          return actions.map(doc => {
            const data = doc.payload.doc.data();
            const id = doc.payload.doc.id;
            return { docId: id, ...data };
          });
        })
      );
  }

  public removeBookmark(docId: string, userId: string): Observable<any> {
    const obs = new Observable((observer) => {
      this.db.collection('users').doc(userId).collection('bookmark')
        .doc(docId).delete().then(async () => {
        observer.next({
          message: this.constVar.bookmarkDelete,
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.Error,
          status: 500,
        });
      });
    });
    return obs;
  }

  public updateBookmark(docId: string, data: any, userId: string): Observable<any> {
    const obs = new Observable((observer) => {
      this.db.collection('users').doc(userId).collection('bookmark').doc(docId).update(data).then(async () => {
        observer.next({
          message: this.constVar.bookmarkUpdate,
          status: 200,
        });
      }).catch((error: any) => {
        observer.next({
          message: error.Error,
          status: 500,
        });
      });
    });
    return obs;
  }
}
