export class ConstantVariables {
  public appVersion = '2.2.0';
  public trackerWidgetColors = [
    {
      background: '#F2D8FC',
      border: '#ff4800',
      progressBar: '#ff2b00',
    },
    {
      background: '#D8DFFC',
      border: '#5D7CFA',
      progressBar: '#ff8000',
    },
    {
      background: '#D8FCF9',
      border: '#82FDF2',
      progressBar: '#ffd500',
    },
    {
      background: '#FCF5D8',
      border: '#FAE67A',
      progressBar: ' #d4ff00',
    },
    {
      background: '#DBFCD8',
      border: '#E2A5FA',
      progressBar: '#5f0',
    },
    {
      background: '#D8EDFC',
      border: '#5D7CFA',
      progressBar: '#0fa;',
    },
    {
      background: '#D8EDFC',
      border: '#5D7CFA',
      progressBar: '#0af;',
    },
    {
      background: '#D8EDFC',
      border: '#5D7CFA',
      progressBar: '#002bff;',
    },
    {
      background: '#D8EDFC',
      border: '#5D7CFA',
      progressBar: '#50f;',
    },
    {
      background: '#D8EDFC',
      border: '#5D7CFA',
      progressBar: '#f0a;',
    },
    {
      background: '#D8EDFC',
      border: '#5D7CFA',
      progressBar: '#ff002b;',
    },
  ];
  public PREMIUM_USER = 'premiumUsers';
  public GET_PUBLISH_KEY = 'getPublishableKey';
  public CREATE_CHECKOUT_SESSION = 'createCheckoutSession';
  public CHECKOUT_SESSION = 'checkoutSession';

  // common
  public someIssue = 'There was an issue. Please contact admin at gunjan@bettertoday.app for help';
  public appUpdateTitle = 'New update available';
  public monthlyAmount = 4;
  public yearlyAmount = 40;
  public MONTHLY_SUBSCRIPTION = 'monthly';
  public ANNUAL_SUBSCRIPTION = 'annual';
  public defaultTitle = 'Better Today';

  public uplodeImgHeight = 1024;
  public uplodeImgwidth = 1024;
  public userMemberShip = 'Account updated successfully';
  public loginDetailMsg = 'Please login to your account at app.bettertoday.app and upgrade there';
  public commonError = 'There is some issue...';

  public userType = {
    freeUser: 'freeUser',
    premiumUsers: 'premiumUsers',
    premiumTrial: 'premium trial'
  };

  // ------------------------------- ANALYSTIC MSG -------------------------------
  public analytics = {
    bookmarkAddStart: 'bookmark_add_started',
    bookmarkUpdateStart: 'bookmark_update_started',
    bookmarkUpdated: 'bookmark_update_done',
    bookmarkCreated: 'bookmark_add_done',
    bookmarkRemoved: 'bookmark_removed',
    deleteAccountStart: 'account_delete_started',
    deleteAccountConfirmed: 'account_delete_done',
    defaultMemoryCreated: 'default_memory_created',
    journalCreated: 'journal_created',
    todoUpdated: 'todo_updated',
    todoCreated: 'todo_created',
    todoTimerStart: 'todo_timer_started',
    todoDetailStart: 'todo_detail_started',
    loginStart: 'login_started',
    signup: 'sign_up',
    login: 'login',
    tutorial: 'tutorial_started',
    userSession: 'user_session_started',
    goalCreated: 'goal_created',
    goalUpdated: 'goal_updated',
    todoCreateStart: 'todo_create_started',
    todoUpdateStart: 'todo_update_started',
    upgradeDismissed: 'upgrade_dismissed',
    upgradeStart: 'upgrade_started',
    upgradeCompleted: 'purchase',
    trackerCreateStart: 'tracker_create_started',
    trackerCreateComplete: 'tracker_create_done',
    trackerAddValue: 'tracker_value_added',
    trackerUpdateValue: 'tracker_value_updated',
    share: 'share',
    upgradeClick: 'upgrade_click',
    gratitude: 'gratitude_photo',
    memory: 'memory_photo'
  };

  // ------------------------------HELP LEARN MSG--------------------------------------
  public stepsData: any = { steps: [] };
  public helpGoal = {
    learnGoalWelcome: {
      title: 'Set and achieve goals',
      text: 'Create, manage and achieve your goals smartly with Better Today<br><span class="small-help-text">Click "Next" to continue</span>'
    },
    learnGoalMsg: {
      title: 'These are your top goals',
      text: '<li>This is where you see you top goals at a glance <li>You can sort your goals and move them to the top from the list of all gaols <br><span class="small-help-text">Click "Next" to continue</span>'
    },
    learnGoalItem: {
      title: 'This is a goal',
      text: '<li>You can click your goal to see the details, set due dates, add tasks for this goal and so on<li>You can also see the progress of your goal under the goal title<li>The progress increases as you complete the related todos<li>You can change the order of goals from the goal list.<span class="small-help-text">Click "Next" to continue</span>'
    },
    learnAddGoal: {
      title: 'Create new goals',
      text: '<li>Add a new goal by clicking here<li>You can create as many goals as you want<li>For each goal you can also set due date, lists of tasks/todos to achieve the goal and other details<span class="small-help-text">Click "Done" to exit</span>'
    },
    learnGoalList: {
      title: 'Manage all your goals',
      text: '<li>Click here to view and manage all your goals<br><li>You can reorder your goals, mark them as completed or view more details<li>You can also add unlimited goals on the goals screen<span class="small-help-text">Click "Next" to continue</span>'
    },
    learnGoalDate: {
      title: `View goals completed on any day`,
      text: `Tap here to select any date. This screen will show you all the goals that you completed on the selected date.
      <span class="small-help-text">Click "Done" to continue</span>`
    }
  };
  public helpTodo = {
    learnTodoWelcome: {
      title: 'Todos done smartly',
      text: `<li>All your tasks of the day can be managed with Better Today
      <li>Prioritize them by dragging important tasks up
      <li>Hide tasks that depend on other tasks
      <li>Hide tasks that you don't have to work on today
      <li>Track how much time you spent on each task
      <li>Use Pomodoro timers for focussed work and more
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnTodoMsg: {
      title: 'Your tasks and todos',
      text: `This section shows all the things that you have to work on
      <span class="small-help-text">Click "Next" to continue</span>
      `
    },
    learnAddTodo: {
      title: 'Quick add a todo',
      text: `Just enter your task here and hit enter or return in the keyboard
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnTodoItem: {
      title: `This is a todo item`,
      text: `<li>Tap on this todo to edit it
      <li>After tapping, click on "Details" to view more details like due date, dependent task and so on
      <li>Slide to get option to edit or delete this todo
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnTodoList: {
      title: `See all your todos`,
      text: `<li>Tap here to view all your pending todos 
      <li>There you can drag to move important todos up
      <li>You can also see the overdue todos, todos for future and completed todos
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnTodoTimer: {
      title: `Focus and track using timers`,
      text: `This shows how much time you have spent on this task.
      Tap here to get options like
      <li>Updating the time spent on this task
      <li>Starting a Pomodoro timer for deep focussed work in 25 minute intervals
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnTodoDate: {
      title: `View todos done on any day`,
      text: `Tap here to select any date. This screen will show you all the todos that you completed on the selected date.
      <span class="small-help-text">Click "Done" to continue</span>`
    }
  };
  public helpJournal = {
    learnJournalWelcome: {
      title: `Your private journal`,
      text: `<li>Document your gratitude and memories for each day
      <li>Research has shown that journalling makes your more mindful of your days
      <li>Gratitude journal makes you a happier personal
      <li>All your entries are private to you.
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnMemoryAdd: {
      title: `Add a new memory`,
      text: `<li>Tap here to add a new memory for today
      <li>You can add photos or type your entry or do both
      <li>Add as many entries as you want
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnGratitudeAdd: {
      title: `Add a new gratitude entry`,
      text: `<li>Tap here to add a new gratitude entry for today
      <li>You can add photos or type your entry or do both
      <li>Add as many entries as you want
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnGratitudeList: {
      title: `View all gratitudes`,
      text: `Tap here to see all your gratitude entries
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnMemoriesList: {
      title: `View all memories`,
      text: `Tap here to view all your memories
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnGratituteItem: {
      title: `This is a gratitude entry`,
      text: `This is a gratitude entry
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnMemoryItem: {
      title: `This is a memory entry`,
      text: `This is a memory entry
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnMemoryItemDashboard: {
      title: `Journal entries for today`,
      text: `Here are all the journal entries that you did today.
      <br>If you add more entries, they start a slideshow here
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnMemorySelcted: {
      title: `Memories section`,
      text: `Tap here to see all your memories
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnGratitudeSelcted: {
      title: `Gratitude section`,
      text: `Tap here to see all your gratitude entries
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnMemoryActionButton: {
      title: `Edit/delete the entry`,
      text: `Tap here to edit or delete this entry
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnGratitudeActionButton: {
      title: `Edit/delete the entry`,
      text: `Tap here to edit or delete this entry
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnJournalDate: {
      title: `Older journal entries`,
      text: `Tap here to select any date and view all entries of that date
      <span class="small-help-text">Click "Done" to continue</span>`
    },
  };

  public helpTracker = {
    learnHabitTrackerWelcome: {
      title: `Custom habit trackers`,
      text: `Build good habits with personalized habit trackers of Better Today
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnHabitTrackerMsg: {
      title: `Habit tracker section`,
      text: `<li>These are the habits that you are tracking
      <li>You can also use this to keep track of things that you need to do regularly
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnHabitTrackerAdd: {
      title: `Add a new habit to track`,
      text: `<li>Tap here to add a new habit tracker
      <li>You can specify tasks that you want to repeat each day (like Brush 2 times daily)
      <li>Or you can track numbers (like Hours slept, Distance run, Words written etc)
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnHabitTrackerItem: {
      title: `This is a habit`,
      text: `
      <li>You can add an entry by tapping + or - button</li>
      <li>Or tap on the tracker to get options like
      <li>- Add/update an entry
      <li>- View trends, analysis, entries and streak
      <li>- Change this habit like title, repetitions etc
      <li>- Delete this habit tracker
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnHabitTrackerDate: {
      title: `Review old entries`,
      text: `Tap here and select any date to see all the entries of that date
      <span class="small-help-text">Click "Done" to continue</span>`
    }
  };
  public helpBookmark = {
    learnBookmarkWelcome: {
      title: `Bookmarks section`,
      text: `This is where you keep all your useful links. This is very useful when you are using the Chrome extension of Better Today.
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnBookmarkMsg: {
      title: `These are the bookmarks`,
      text: `These are the links that you saved
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnAddBookmarkMsg: {
      title: `Add a new bookmark`,
      text: `Tap here to add a new bookmark. You can save upto 12 bookmarks
      <span class="small-help-text">Click "Next" to continue</span>`
    },
    learnBookmarkItem: {
      title: `This is a bookmark`,
      text: `<li>Tap on the bookmark to open it in the browser
      <li>Slide the bookmark to edit or delete it
      <span class="small-help-text">Click "Next" to continue</span>`
    },
  };

  // ----------------------------------HELP LEARN IDS-----------------------------------
  public homeDateId = 'home_date';

  public habitTrackersId = {
    habitTrackersId: 'habitTrackers',
    addTrackersId: 'addTrackers',
    itemTrackersId: 'itemTrackers',
  };
  public habitTrackersIdForHome = {
    habitTrackersId: 'habitTrackers-home',
    addTrackersId: 'addTrackers-home',
    itemTrackersId: 'itemTrackers-home',
    habitTrackersDateId: 'home_date',
  };
  public todosId = {
    todosId: 'todos',
    addTodoId: 'addTodo',
    todoItemId: 'todoItem',
    todoListId: 'todoList',
    todoTimerId: 'learnTodoTimer',
  };
  public todosIdForHome = {
    todosId: 'todos-home',
    addTodoId: 'addTodo-home',
    todoItemId: 'todoItem-home',
    todoListId: 'todoList-home',
    todoTimerId: 'learnTodoTimerHome',
    todoDateId: 'home_date',
  };
  public goalId = {
    goalsLearnId: 'goalsLearn',
    addGoalId: 'addGoal',
    goalItemId: 'goalItem',
    allGoalsId: 'allGoals'
  };
  public goalIdForHome = {
    goalsLearnId: 'goalsLearn-home',
    addGoalId: 'addGoal-home',
    goalItemId: 'goalItem-home',
    allGoalsId: 'allGoals-home',
    goalsDateId: 'home_date'
  };
  public journalId = {
    memoriesSelectedId: 'memoriesSelected',
    gratitudeSelectedId: 'gratitudeSelected',
    memoriesAddId: 'memoriesAdd',
    gratitudeAddId: 'gratitudeAdd',
    memoriesItemId: 'memoriesItem',
    gratitudeItemId: 'gratitudeItem',
    memoriesActionButtonId: 'memoriesActionButton',
    gratitudeActionButtonId: 'gratitudeActionButton',
  };
  public journalIdForGratitude = {
    memoriesId: 'memories-grat',
    gratitudeId: 'gratitude-grat',
    memoriesItemId: 'memoriesItem-grat',
    gratitudeItemId: 'gratitudeItem-grat'
  };
  public journalIdForHome = {
    memoriesAddId: 'memories-home',
    gratitudeAddId: 'gratitude-home',
    memoriesItemId: 'memoriesItem-home',
    gratitudeItemId: 'gratitudeItem-home',
    journalDateId: 'home_date',
  };

  // hidecontent
  public hideMemories = 'Your memories are one of your most underrated superpowers';
  public hideGratitude = 'Gratitude makes sense of our past, brings peace for today and creates a vision for tomorrow';
  public hideBookmark = 'The attitude you pose is greatly influenced by the links of friendships you bookmark';
  // tslint:disable-next-line: max-line-length
  public hideTodos = ' The secret of getting started is breaking your complex overwhelming tasks into small manageable tasks and starting on the first one';
  public hideStaftracked = 'What gets tracked gets managed';

  // nocontent
  public noMemoriesTitle = 'Do you have a memory for today? Add one now';
  public noMemoriesSubTitle = `Time flies don't let the special moments of today fade with it`;
  public noGratitudeTitle = 'What made you smile today';
  public noGratitudeSubTitle = `Saying thank you makes you happier`;
  public noTodosTitle = 'Add some todos';
  public noTodosSubTitle = `You will be more productive if you break complex tasks into smaller tasks`;
  public noTrackTitle = 'Add something to track';
  public noTrackSubTitle = `Track stuff that matters to you like glasses of water, your weight, sleep etc`;

  // bookmark
  public bookmarkTitle = 'Web bookmarks';
  public addShortcut = 'Add shortcut';
  public bookmarkAdd = 'Bookmark added successfuly';
  public bookmarkDelete = 'Bookmark deleted successfuly';
  public bookmarkUpdate = 'Bookmark updated successfuly';

  // header
  public today = 'Today';
  public memories = 'Memories';
  public gratitudes = 'Gratitudes';
  public signIn = 'Sign in';
  public upgrade = 'Upgrade';
  public help = 'Help';
  public upgradeTitleForUpgradeMenu = 'Uploading photos is a premium feature';
  public upgradeSubTitleForUpgradeMenu = 'Add color to your memories by adding photos to your memories of the day';
  // tslint:disable-next-line: max-line-length
  public managePremiumUsers = 'This feature is available to Premium users. Premium users can manage their motivational quotes and put the quotes that work best for them';

  // profile
  public profileUpdateSuccess = 'Profile updated successfully';
  public logoutMsg = 'Profile logout successfully';
  public invalidEmailId = 'Email address is invalid';
  public uploadImg = 'Image updated successfully';
  public invalidEmail = 'Email address does not match';

  // setting
  public visibilityOfBlock = 'You can toggle the visibility of each block';
  public ableToaddContents = 'You will still be able to add contents to these blocks';
  public ResetDefaultMemory = 'Select this and save to clear default memory';
  public SelectDefaultPhoto = 'Select a default photo for the memories placeholder';
  public memorySetDefault = 'Default photo for memory set successfully';
  public deleteDefaultMemories = 'Default photo for memories cleared';
  public settingUpdateSuccess = 'Settings updated successfuly';
  public upgradeTitleForSetting = 'Uploading photos is a premium feature';
  public upgradeSubTitleForSetting = 'Add color to your memories by adding photos to your memories of the day';
  // quotes
  public quotesAddSuccess = 'Quotes added successfuly';
  public quotesUpdateSuccess = 'Quotes updated successfuly';
  public quotesDeleteSuccess = 'Quotes deleted successfuly';
  public updateTimeIntr = 'Updated interval time successfuly';
  public upgradeTitleForQuotes = 'Uploading photos is a premium feature';
  public upgradeSubTitleForQuotes = 'Add color to your memories by adding photos to your memories of the day';
  // tslint:disable-next-line: max-line-length
  public onlyPremiumUsers = 'This feature is available to Premium users. Premium users can manage their motivational quotes and put the quotes that work best for them';

  // health staf
  public addedStuff = 'Added an item to track';
  public healthStuffDataUpdate = 'Updated an item to track';
  public healthStuffDataDelete = 'Deleted an item to track';
  public valueReset = 'Value reset';
  public inputInvalid = 'Input is invalid';
  public inputInvalidIcon = 'Value is greater than goal';
  public dailyHealthStatusUpdate = 'Value updated';
  public addedDailyStatus = 'Value saved';
  public healthStuffManage = 'This feature is available to Premium users';
  public upgradeTitleForHealthStuff = 'Adding custom trackers is a premium feature';
  public upgradeSubTitleForHealthStuff = 'Build more habits and track unlimited items as a premium user';
  public visitedApp = `Visit BetterToday.app from the browser in your laptop to add more items to track or change these items`;
  public healthStuffHeading = 'Tracking';
  public upgradeIconForHealthStuff = 'pulse-outline';

  // todos
  public todosDeleted = 'Todos deleted';
  public todosMangeUser = 'This feature is available to Premium users';
  public addTodos = 'Todos added';
  public updateTodos = 'Todos updated';
  public reorderTodos = 'Order updated';
  public completedTodos = 'Todo marked as done';
  public unCompletedTodos = 'Todos moved to pending list';
  public upgradeTitleForTodos = 'Pomodoro timer is a premium feature';
  public upgradeSubTitleForTodos = 'Focus on each task and keep track of time spent with an interval timer';
  public getUndoneTodosMsg = 'Get all Undone todoslist successfully.';
  public todosUpdateSuccess = 'Todos updated successfuly.';
  public todosAddSuccess = 'Todos added successfuly.';
  public getDailyDoneTodosSuccess = 'Get Daily Done TodosList successfully.';
  public getAllCompletedTodosSuccess = 'Get all completed TodosList successfully.';
  public getAllDoneTodosSuccess = 'Get all Done TodosList successfully.';
  public getTodosListMsg = 'Get all TodosList successfully.';
  public noTodosDataForPreviousDate = 'No completed todos for this date';

  // Goals
  public goalsDeleted = 'Goals deleted';
  public noGoalsTitle = 'Add some goals';
  public noGoalsSubTitle = `You will be more productive if you add complex tasks into goals`;
  public goalsCheckbox = 'Complete all todos of this goal';
  public goalsDeleteCheckbox = 'Delete the todos of this goal';
  public goalsDeletedSuccess = 'Goal deleted successfully';
  public goalsUpdatedSuccess = 'Goal updated successfuly.';
  public updateGoals = 'Goal updated';
  public upgradeTitleForGoals = 'Customizing goals is a premium feature';
  public upgradeSubTitleForGoals = 'Customize goals with icons, colors etc to make it more effective';
  public goalAddSuccess = 'Goal added successfully';

  public undoneGoalTitle = 'Attention!';
  public undoneGoal = 'Are you sure you want to mark this goal as pending';
  public doneGoalTitle = 'Goal achieved';
  public doneGoal = 'Are you sure you want to mark this goal as achieved';
  public noGoalsDataForPreviousDate = 'No completed goals for this date';


  // journal
  public reset = 'Entries reset, start over';
  public notingToSave = '<strong>Nothing to save</strong> Please select atleast a file or some text remember the day';
  public your = 'Your';
  public saveSuccess = 'from this day saved';
  public firstLogin = 'Please login first';
  public jounalMangeUser = 'This feature is available to Premium users only';
  public invalidUrl = 'invalid url';
  public upgradeTitleForJournal = 'Uploading photos is a premium feature';
  public upgradeSubTitleForJournal = 'Add color to your memories by adding photos to your memories of the day';
  public errorMessage = 'There are some error in updating data';
  public confirmMessageTitle = 'Attention';
  public confirmMessage = 'Are you sure you want to remove';
  public fullUpdrateTitle = 'Upgrade to unlock the full potential';
  public fullUpdrateSubTitle = 'It also enables us to work more on this and add awesome features for you';


  // memory
  public manageMemory = `This feature is available only to premium users. If you wish to change this entry or add another entry for this day, then please delete it here and visit that date from the main screen and add a new entry`;
  public deleteMsg = 'Deleted successfully';
  public addMsg = ' added successfully';
  public updateMsg = 'Updated successfully';
  public setDefalut = 'Default placeholder image set successfully';
  public upgradeTitleForMemory = 'Uploading photos is a premium feature';
  public upgradeSubTitleForMemory = 'Add color to your memories by adding photos to your memories of the day';
  memoryPhotoDeleteMsg = 'Memory photo removed successfully';
  public memoryUpdateMsg = 'Memory updated successfully';
  public memoryDeleteMsg = 'Memory deleted successfully';


  // gratitude
  // "icon": "fas fa-camera-retro",
  public upgradeTitleForGratitude = 'Uploading photos is a premium feature';
  public upgradeSubTitleForGratitude = 'Add emotion to your gratitudes by adding photos to the gratitude entries';
  gratitudePhotoDeleteMsg = 'Gratitude photo removed successfully';
  public gratitueUpdateMsg = 'Gratitude updated successfully';
  public gratitueDeleteMsg = 'Gratitude deleted successfully';



  // why-signup
  public autoBackup = 'Automatic backup of data for life';
  public loseEverything = 'Right now, if your chrome or computer crashes, you lose everything';
  public anywhere = 'Access your data from anywhere';
  public freedom = ' Get the freedom to manage your day from any computer';
  public manageYourDay = ' Manage your day from any app';
  public usingBrowsers = `Manage your day using Chrome, Firefox, Safari, Opera, Mobile apps for Android and iOS and Desktop apps for Windows, Mac and Ubuntu (coming soon)`;
  public weeklyOverview = 'Get a weekly overview';
  public everyWeek = 'Every week, we will send you an overview of the past week on your email (coming soon)';

  // chack email
  public checkEmail = 'please check your email for the link to login';

  // signup component
  public neverLose = 'Never lose your memories. Automatic backup for life';
  public accessYourJournal = 'Access your journal from any browser on any PC';
  public multipleApps = 'Manage your day from multiple apps for desktop and mobile *';
  public getWeeklyOverview = 'Get weekly overview on email *';
  public comingSoon = '* Coming soon';

  // delete account msg
  public deleteAccount = 'Are you sure you want to permanently delete your account and all your data.';

  public confDeleteAccount = 'Are you sure you want to permanently delete your account and all your data.';
  public confDeleteMidMsg = 'You can still cancel it by clicking "Cancel" or confirm it by clicking "Delete"';
  public confDeleteBottamMsg = 'If you want to use the app again, you can signup again as a new user.';
  public userDeleteMsg = 'Account deleted successfully';
  // public hostName = 'https://bettertoday.app';
  public hostName = 'https://dailyaide-dev.web.app';

  // ERROR HANDLING MSG
  public errorMsg = 'Something went wrong';
  public errorcolor = 'danger';

  // help
  proTrailerMsg = {
    awesomeMsg: 'You are awesome!',
    smallGiftMsg: 'Here is a small gift to get you started',
    proTillMsg: 'We upgraded you to PRO till',
    detail: 'you can either upgrade to PRO to use the PRO features or continue using all the amazing free features.',
    about: 'All your memories, goals, todos, trackers will be available forever'
  };
  freeUserMsg = {
    awesomeMsg: 'You are now using Better Today as a free user',
    smallGiftMsg: 'We made it easier for you to upgrade to PRO',
    proTillMsg: 'Upgrade to PRO at half the price',
    couponCode: 'THANKU50',
    off: '50%',
    offFrom: 'monthly or annual plans'
  };
  proUserMsg = {
    awesomeMsg: 'You get personal assistance by email',
    smallGiftMsg: 'Contact us at gunjan@bettertoday app',
    proTillMsg: 'We respond within 48 hours',
    detail: 'Whether you are facing a problem or have ideas for features that will make Better Today more useful for you.',
    about: 'Please email us and we will get back to you withen 48 hours'
  };

  // PAYMENT SECTION
  public useFreeUserDay = 30;
  public cancelPaymentTitle = 'Payment Cancelled';
  public cancelPaymentSubTitle = 'Oops! You cancelled your payment. You can try again later';
  public transactionNote = 'Free User';
  public addTransactionsMsg = 'add Transactions successfuly';
  public successPaymentTitle = 'Payment Successful';
  public successPaymentSubTitle = 'Congratulations! Your upgrade was successful.';
  public errorPaymentTitle = 'Unknown Error';
  public errorPaymentSubTitle = 'There is some error, Please check your sessionId';

  public PRODUCT_MONTHLY_KEY = 'promonthly';
  public PRODUCT_ANNUAL_KEY = 'proannual';

  public colorPicker = [
    {
      color: '#e8e8e8',
    },
    {
      color: '#bfbfbf',
    },
    {
      color: '#737373',
    },
    {
      color: '#4d4d4d',
    },
    {
      color: '#ffff00',
    },
    {
      color: '#ffae00',
    },
    {
      color: '#ff7700',
    },
    {
      color: '#ff4400',
    },
    {
      color: '#839400',
    },
    {
      color: '#1e9400',
    },
    {
      color: '#00e0d1',
    },
    {
      color: '#00b1cc',
    },
    {
      color: '#008bcc',
    },
    {
      color: '#004bcc',
    },
    {
      color: '#ff14f3',
    },
    {
      color: '#a12eff',
    },
    {
      color: '#5c00cc',
    },
    {
      color: '#ff149d',
    },
    {
      color: '#d60019',
    },
  ];

  public tempTodosObj = [
    {
      name: 'Overdue',
      total: 0,
      items: [],
      show: true,
    },
    {
      name: 'Today',
      total: 0,
      items: [],
      show: false,
    },
    {
      name: 'Future',
      total: 0,
      items: [],
      show: true,
    },
    {
      name: 'Completed',
      total: 0,
      items: [],
      show: false,
    },
  ];

  // SET LIMIT IN API BY CONST FILE
  // ******GOALS SECTION*******
  public setGoalUndoneFirstLimit = 4;
  public setGoalUndoneNullLimit = 100;
  public setGoalDoneFirstLimit = 4;
  public setGoalDoneNullLimit = 100;
  public setGoalDashboardLimit = 1;

  // ****** TODOS SECTION *******
  public setTodosDashboardLimit = 3;
  public setTodosUndoneNullLimit = 100;

  // ****** HEALTH CHART SECTION *******
  public setChartValueLimit = 7;
  public getDailyHealthNullLimit = 100;
  public getDailyHealthOneLimit = 1;

  // Todo And Gola done sound
  public todoGoalDoneSound = '/assets/audio/doneping.mp3';
  public timerSound = '/assets/audio/ping.wav';
  public stopTimerSound = '/assets/audio/beep.wav';


  

  public INTERVALTIME: string[] = [
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM'
  ];

  public EXPECTEDTIME: any[] = [
    { value: '00:05', label: '5 mins', },
    { value: '00:10', label: '10 mins', },
    { value: '00:15', label: '15 mins', },
    { value: '00:30', label: '30 mins', },
    { value: '01:00', label: '1:00 hour', },
    { value: '01:30', label: '1:30 hours', },
    { value: '02:00', label: '2:00 hours', },
    { value: '02:30', label: '2:30 hours', },
    { value: '03:00', label: '3:00 hours', },
    { value: '03:30', label: '3:30 hours', },
    { value: '04:00', label: '4:00 hours', },
    { value: '04:30', label: '4:30 hours', },
    { value: '05:00', label: '5:00 hours', },
    { value: '05:30', label: '5:30 hours', },
    { value: '06:00', label: '6:00 hours', },
    { value: '06:30', label: '6:30 hours', },
    { value: '07:00', label: '7:00 hours', },
    { value: '07:30', label: '7:30 hours', },
    { value: '08:00', label: '8:00 hours', },
    { value: '08:30', label: '8:30 hours', },
    { value: '09:00', label: '9:00 hours', },
    { value: '09:30', label: '9:30 hours', },
    { value: '10:00', label: '10:00 hours', },
    { value: '10:30', label: '10:30 hours', },
    { value: '11:00', label: '11:00 hours', },
    { value: '11:30', label: '11:30 hours', },
    { value: '12:00', label: '12:00 hours', },
  ];



}

