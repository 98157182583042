import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ConsoleService } from '../console.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private angularFireAnalytics: AngularFireAnalytics,
    private console: ConsoleService,

  ) { }

  public logEvent(title: any, data = null): void {
    // tslint:disable-next-line: no-unused-expression
    this.console.log('************logEvent**************', `${title} ${data}`);
    this.angularFireAnalytics.logEvent(title, data);
  }
}
