<ion-header translucent>
  <ion-toolbar>
    <ion-title>
      <span class="titile_icon">
        <ion-icon name="information-circle-outline"></ion-icon>
      </span>
      Release notes
    </ion-title>
    <ion-buttons slot="end" class="ion-no-margin">
      <ion-button (click)="closeModal()" color="danger">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row *ngIf="versionListData">
      <ion-col>
        <ion-list>
          <ion-item *ngFor="let item of versionListData">
            <ion-label [ngClass]="item.url ? 'label-margin' : ''">
              <h2>{{item.versionNo}}</h2>
              <div [innerHTML]="item.message"></div>
              <a *ngIf="item.url" (click)="clickOnReadMore(item)" class="read-more">Read more</a>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-row>
    <ion-col>
      <ion-button class="dismiss-btn custom-button" expand="block" (click)="closeModal()">Dismiss
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>