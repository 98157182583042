import { Injectable } from '@angular/core';
import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2/ngx';
import { GlobalService } from '../services/global/global.service';
import { ConstantVariables } from 'src/const/constant';
import { Platform, PopoverController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UserLoginService } from './user-login/user-login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionModalComponent } from '../shared-ui-component/action-modal/action-modal.component';
import { ConsoleService } from '../services/console.service';


@Injectable({
  providedIn: 'root'
})
export class InAppService {
  public products: IAPProduct[] = [];
  public gems = 0;
  public isPro = false;
  userData: any;

  constructor(
    private store: InAppPurchase2,
    private globalService: GlobalService,
    public constVar: ConstantVariables,
    private platform: Platform,
    private userLoginService: UserLoginService,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private console: ConsoleService,
  ) {
    // this.getUserData();

    if (this.userLoginService.infoCurrentUser) {
      this.console.log('user exist', this.userLoginService.infoCurrentUser);
    } else {
      this.userLoginService.getUserData().subscribe((userData: any) => { });
    }
  }

  createInApp() {
    this.platform.ready().then(() => {
      // Only for debugging!
      this.store.verbosity = this.store.DEBUG;
      this.registerProducts();
      this.setupListeners();
      // Get the real product information
      this.console.log('this.store', this.store);
      this.store.ready(() => {
        this.products = this.store.products;
        this.console.log('products', this.products);
        this.globalService.inApp(this.store.products);
      });
    });
  }

  private registerProducts() {
    this.store.register({
      id: this.constVar.PRODUCT_MONTHLY_KEY,
      type: this.store.PAID_SUBSCRIPTION,
    });

    this.store.register({
      id: this.constVar.PRODUCT_ANNUAL_KEY,
      type: this.store.PAID_SUBSCRIPTION,
    });

    // Show errors for 10 seconds.
    this.store.error((error) => {
      this.console.log('ERROR in store', error);
    });
    this.store.refresh();
  }

  private setupListeners() {
    // General query to all products
    this.store.when('product')
      .approved((p: IAPProduct) => {
        this.console.log('2000 Product approved', p);
        return p.verify();
      })
      .verified((p: IAPProduct) => {
        this.console.log('2000 Product verified', p);
        p.finish();
      })
      .owned((p: IAPProduct) => {
        this.console.log('2500 Owned', p);
        // Upgrade the user
        if (p.priceMicros !== null) {
          this.console.log('2510 Product owned so upgrading user now', p);
          if (p.owned === true) {
            this.upgradeUser(p);
          }
        }
      });

    this.store.when('subscription').updated((res) => {
      this.console.log('2600 subscription updated', res);

      // Check for status change. If owned = false then make this user freeUser

      const product1: any = this.store.get(this.constVar.PRODUCT_MONTHLY_KEY) || {};
      const product2: any = this.store.get(this.constVar.PRODUCT_ANNUAL_KEY) || {};

      let status = 'Please subscribe below';
      if (product1.owned || product2.owned) {
        status = 'Subscribed';
        if (product1.owned === true) {
          this.checkUserProductStatus(product1);
        } else if (product2.owned === true) {
          this.checkUserProductStatus(product2);
        }

      } else if (product1.state === 'approved' || product2.state === 'approved') {
        status = 'Processing...';
      } else if (!product1.owned && !product2.owned) {
        this.downgradeUserTofreeUser();
      }
      // setState({ product1, product2, status });
    });
  }

  private downgradeUserTofreeUser(): void {
    // !environment.production && this.console.log(`2800 Checking whether to downgrade user to free and userData is `, this.userData);
    // this.userLoginService.getUserData().subscribe((userData: any) => {
    //   if (userData[0].data.userType === 'premiumUser') {
    //     userData[0].data.userType  = 'freeUser';
    //     !environment.production && this.console.log(`2810 User type is premiumUser so running query`, this.userData);
    //     this.userLoginService.updateUserData(userData[0].data, 'freeUser').subscribe((responseData: any) => {
    //       !environment.production && this.console.log('responseData', responseData);
    //     });
    //   }
    // });


    if (this.userLoginService.infoCurrentUser) {
      if (this.userLoginService.infoCurrentUser.data.userType === this.constVar.userType.premiumUsers) {
        this.userLoginService.infoCurrentUser.data.userType = this.constVar.userType.freeUser;
        this.console.log(`2810 User type is premiumUser so running query`, this.userData);
        // tslint:disable-next-line:max-line-length
        this.userLoginService.updateUserData(this.userLoginService.infoCurrentUser.data, this.constVar.userType.freeUser).subscribe((responseData: any) => {
          this.console.log('responseData', responseData);
        });
      }
    }
  }

  private checkUserProductStatus(p): void {
    this.console.log('2700 checking user status for product ', p);
    let osStoreReceipt;
    if (p.transaction.type === 'android-playstore') {
      osStoreReceipt = p.transaction.receipt;
    } else {
      osStoreReceipt = p.appStoreReceipt;
    }
    if (p.owned === true) {
      this.userLoginService.checkAppStoreReceiptExist(this.userData, osStoreReceipt).subscribe(data => {
        if (data.length) {
          this.console.log('2710 User already pro and owns the product', data);
        } else {
          this.console.log('2720 User not pro and owns the product', data);
          this.upgradeUser(p);
        }
      });
    }
  }

  public purchase(product: IAPProduct): Observable<any> {
    const obj = new Observable((observer) => {
      this.store.order(product).then((p: any) => {
        this.console.log('Purchase in progress ', p);
        // Purchase in progress!
        observer.next({
          status: 200,
          data: p
        });
      }, (e: any) => {
        this.console.log('error', e);
        observer.next({
          status: 500,
          data: e
        });
      });

    });
    return obj;
  }

  // To comply with AppStore rules
  public restore() {
    this.store.refresh();
  }

  public upgradeUser(res): void {
    // !environment.production && this.console.log(`2100 upgrading user with `, res);
    // this.userLoginService.getUserData().subscribe((responseData: any) => {
    //   if (responseData && responseData.length) {
    //     this.userData = responseData[0].data;

    //     let osStoreReceipt;
    //     if (res.transaction.type === 'android-playstore') {
    //       osStoreReceipt = res.transaction.receipt;
    //     } else {
    //       osStoreReceipt = res.transaction.appStoreReceipt;
    //     }
    //     !environment.production && this.console.log(`2111 App store receipt for type ${res.transaction.type} is ${osStoreReceipt}`);

    //     // this.userLoginService.checkAppStoreReceiptExist(this.userData, res.appStoreReceipt).subscribe(data => {
    //     this.userLoginService.checkAppStoreReceiptExist(this.userData, osStoreReceipt).subscribe(data => {
    //       if (data && data.length) {
    //         !environment.production && this.console.log('2110 Receipt already exists in transaction table');
    //       } else {
    //         // tslint:disable-next-line: no-unused-expression
    //         !environment.production && this.console.log(`2120 upgrading user with object `, res);
    //         let subType;
    //         if (res.id === 'promonthly') {
    //           subType = 'monthly';
    //         } else {
    //           subType = 'annual';
    //         }
    //         const transactionData: any = {
    //           userId: this.userData.docId,
    //           userEmail: this.userData.email,
    //           firstName: this.userData.firstName,
    //           lastName: this.userData.lastName,
    //           totalAmount: Number(res.priceMicros / 1000000),
    //           discountAmount: 0,
    //           netPayableAmount: Number(res.priceMicros / 1000000),
    //           txnDuration: subType,
    //           hostUrl: window.location.origin,
    //           byInApp: true,
    //           byInAppAmount: Number(res.priceMicros / 1000000),

    //           inAppResponse: res,
    //           currency: res.currency,
    //           appStoreReceipt: res.transaction.type === 'android-playstore' ? res.transaction.receipt : res.appStoreReceip
    //         };
    //         if (res.id === 'promonthly') {
    //           transactionData.inAppMonthlyCharge = Number(res.priceMicros / 1000000);
    //         } else {
    //           transactionData.inAppannualCharge = Number(res.priceMicros / 1000000);
    //         }
    //         !environment.production && this.console.log('2200 transaction data is', transactionData);
    //         this.userLoginService.addTranction(transactionData)
    //           .subscribe(async (respo: any) => {
    //             // this.paymentDoneAlert();
    //             this.successPopover(null);
    //             !environment.production && this.console.log(`2300 got a response from userlogin.addTranction`, respo);
    //           }, (error: HttpErrorResponse) => {
    //             !environment.production && this.console.log('2400 error', error);
    //           });
    //       }
    //     });
    //   }
    // }, (error: HttpErrorResponse) => {
    //   !environment.production && this.console.log('2410 error', error);
    // });

    if (this.userLoginService.infoCurrentUser) {
      this.userData = this.userLoginService.infoCurrentUser.data;

      let osStoreReceipt;
      if (res.transaction.type === 'android-playstore') {
        osStoreReceipt = res.transaction.receipt;
      } else {
        osStoreReceipt = res.transaction.appStoreReceipt;
      }
      this.console.log(`2111 App store receipt for type ${res.transaction.type} is ${osStoreReceipt}`);

      // this.userLoginService.checkAppStoreReceiptExist(this.userData, res.appStoreReceipt).subscribe(data => {
      this.userLoginService.checkAppStoreReceiptExist(this.userData, osStoreReceipt).subscribe(data => {
        if (data && data.length) {
          this.console.log('2110 Receipt already exists in transaction table');
        } else {
          // tslint:disable-next-line: no-unused-expression
          this.console.log(`2120 upgrading user with object `, res);
          let subType;
          if (res.id === 'promonthly') {
            subType = 'monthly';
          } else {
            subType = 'annual';
          }
          const transactionData: any = {
            userId: this.userData.docId,
            userEmail: this.userData.email,
            firstName: this.userData.firstName,
            lastName: this.userData.lastName,
            totalAmount: Number(res.priceMicros / 1000000),
            discountAmount: 0,
            netPayableAmount: Number(res.priceMicros / 1000000),
            txnDuration: subType,
            hostUrl: window.location.origin,
            byInApp: true,
            byInAppAmount: Number(res.priceMicros / 1000000),

            inAppResponse: res,
            currency: res.currency,
            appStoreReceipt: res.transaction.type === 'android-playstore' ? res.transaction.receipt : res.appStoreReceip
          };
          if (res.id === 'promonthly') {
            transactionData.inAppMonthlyCharge = Number(res.priceMicros / 1000000);
          } else {
            transactionData.inAppannualCharge = Number(res.priceMicros / 1000000);
          }
          this.console.log('2200 transaction data is', transactionData);
          this.userLoginService.addTranction(transactionData)
            .subscribe(async (respo: any) => {
              // this.paymentDoneAlert();
              this.successPopover(null);
              this.console.log(`2300 got a response from userlogin.addTranction`, respo);
            }, (error: HttpErrorResponse) => {
              this.console.log('2400 error', error);
            });
        }
      });
    }


  }

  async successPopover(ev: any) {
    this.modalCtrl.dismiss();
    const popover = await this.popoverCtrl.create({
      component: ActionModalComponent,
      event: ev,
      translucent: true,
      animated: true,
      showBackdrop: true,
      cssClass: 'popover_class',
      mode: 'ios',
      componentProps: {
        title: 'Success',
        message: 'Successfully upgraded to pro!',
        img: 'fas fa-crown',
        crown: true,
        buttonType: 'close'
      }
    });
    popover.onDidDismiss().then(() => {
      this.modalCtrl.dismiss('success');
    });
    return await popover.present();
  }
}
