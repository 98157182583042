import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingStatus: any;

  constructor(
    private loadingController: LoadingController,
  ) { }

  public async showLoading() {
    this.loadingStatus = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
    });
    await this.loadingStatus.present();
  }

  public async hideLoading() {
    const loadingExist = document.getElementsByTagName('ion-loading')[0];
    if (loadingExist) {
      await this.loadingStatus.dismiss();
    }
  }
}
